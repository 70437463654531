import axios from 'axios';
import Constants from '../../_shared/Constants';
import { clearLocalStorage, saveToLocalStorage } from '../../_shared/utils/storage';
import { getRefreshToken } from './apiService';

export const getSecureAxios = () => {
    const secureAxios = axios.create({
        baseURL: Constants.app.BASE_URL,
        headers: {
            'Authorization': `Bearer ${window.accessToken}`,
        },
    });
        
    //Add a response interceptor
    secureAxios.interceptors.response.use(
        (response) => {
            return response;
        }, 
        async (error) => {
            const originalRequest = error.config;
    
            // if refresh token expires
            if (
                error?.response?.status === 401 && 
                originalRequest.url === 
                `/auth/token-refresh/`
            ) {
                clearLocalStorage();
    
                // navigate to login page
                window.location.href = window.location.origin + '/login';
                return Promise.reject(error);
            }
            
            // if access token expires
            if (error?.response?.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                const refreshToken = window.refreshToken;
                
                try {
                    const { access } = await getRefreshToken(refreshToken as string);
    
                    // Add the new token to the request header
                    originalRequest.headers.Authorization = `Bearer ${access}`;
                    saveToLocalStorage('accessToken', access);
    
                    // Repeat the current request
                    return secureAxios.request(originalRequest);
                } catch (_error) {
                    return Promise.reject(_error);
                }
            }
            return Promise.reject(error);
        },
    );

    return secureAxios;
};
