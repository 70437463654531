import hexToRgba from 'hex-to-rgba';
import styled from 'styled-components';
import Button from '../../_shared/components/Button';

export const Wrapper = styled.div(({ theme }) => ({
    backgroundColor: hexToRgba(theme.SECONDARY, 0.4),
    minHeight: '100vh'
}));

export const MenuWrapper = styled.div(({ theme }) => ({
    position: 'sticky',
    top: 30,
    backgroundColor: theme.common.WHITE,
    border: `1px solid ${hexToRgba(theme.common.BLACK, 0.1)}`,
    marginRight: 50,
    borderRadius: 6,
    boxShadow: `1px 1px 3px ${theme.SECONDARY}`,
    'p': {
        marginBottom: 2,
        marginTop: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 15,
        width: 130,
    },
}));

export const FormWrapper = styled.form({
    marginBottom: 30,
});

export const FormSubmitButton = styled(Button)(({ theme }) => ({
    marginTop: 25,
    fontSize: 15,
    ':focus': {
         boxShadow: `0 0 0 4px ${hexToRgba(theme.PRIMARY, 0.2)}`,
     },
     width: '100%',
     height: 45,
 }));

 export const ProductDeleteButton = styled(Button)(({ theme }) => ({
    marginTop: 25,
    fontSize: 15,
    ':focus': {
         boxShadow: `0 0 0 4px ${hexToRgba(theme.status.DANGER, 0.2)}`,
     },
     ':hover': {
        backgroundColor: hexToRgba(theme.status.DANGER, 0.8)
     }, 
     width: '100%',
     height: 45,
     backgroundColor: hexToRgba(theme.status.DANGER, 0.9),
 }));

