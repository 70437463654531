
export const saveToLocalStorage = (key: string, value: any) => {
    if (key === 'accessToken') {
        window.accessToken = value;
    } else if (key === 'refreshToken') {
        window.refreshToken = value;
    }

    localStorage.setItem(key, JSON.stringify(value));
};

export const getFromLocalStorage = (key: string) => {
    if (localStorage.getItem(key)) {
        return JSON.parse(localStorage.getItem(key) as string);
    } else {
        return null;
    }
};

export const deleteFromLocalStorage = (key: string) => {
    localStorage.removeItem(key);
};

export const clearLocalStorage = () => {
    window.accessToken = undefined;
    window.refreshToken = undefined;
    localStorage.clear();
};
