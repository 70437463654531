import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
  } from "react-router-dom";
import { 
    Login, 
    PasswordReset, 
    PasswordResetConfirm, 
    Signup, 
    VerifyEmail 
} from '../Authentication';
import Cart from '../Cart';
import { Dashboard, MyProducts, ProductForm } from '../Dashboard';
import Home from "../Home";
import { ProductDetails } from '../Products';
import Constants from "../_shared/Constants";
import NotFound from './components/NotFound';


const { routes: paths } = Constants;

const routes = [
    {
        path: paths.VERIFY_EMAIL,
        component: VerifyEmail,
    },
    {
        path: paths.PASSWORD_RESET_CONFIRM,
        component: PasswordResetConfirm,
    },
    {
        path: paths.PASSWORD_RESET,
        component: PasswordReset,
    },
    {
        path: paths.PRODUCT_DETAILS,
        component: ProductDetails,
    },
    {
        path: paths.LOGIN,
        component: Login
    },
    {
        path: paths.SIGNUP,
        component: Signup
    },
    {
        path: paths.HOME,
        component: Home,
    },
    {
        path: paths.CART,
        component: Cart,
    },
    {
        path: '*',
        component: NotFound,
    },
];

const dashboardRoutes = [
    {
        index: true,
        component: MyProducts,
    },
    {
        path: paths.POST_PRODUCT,
        component: ProductForm,
    },
    {
        path: paths.EDIT_PRODUCT,
        component: ProductForm,
    },
    {
        path: '*',
        component: NotFound,
    },
];

const Navigator = () => {
    return (
        <BrowserRouter>
            <Routes>
                {
                    routes.map(
                        (route, i) => 
                        <Route 
                            key={i} 
                            path={
                                route.path.length === 1 ? 
                                route.path : 
                                route.path.slice(1)
                            } 
                            element={<route.component />} 
                        />
                    )
                }
                {
                    <Route path={paths.DASHBOARD.slice(1)} element={<Dashboard />}>
                        {
                            dashboardRoutes.map(
                                (route, i) => 
                                <Route 
                                    key={i} 
                                    index={route?.index}
                                    path={
                                        route?.path?.length === 1 ? 
                                        route?.path : 
                                        route?.path?.slice(1)
                                    } 
                                    element={<route.component />}
                                />
                            )
                        }
                    </Route>
                }
            </Routes>
        </BrowserRouter>
    );
};

export default Navigator;
