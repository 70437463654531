import React from 'react';
import { EmptyList, Flex, Header } from '../_shared/components';
import CartItem from './components/CartItem';
import { useCart } from '../Cart/hooks/useCart';
import { 
    CartItemsWrapper,
    CartWrapper,
    CartSummary,
    CheckoutButton,
    Wrapper,
 } from './components/styledComponent';
import { useDeviceWidth } from '../_shared/hooks/useDeviceWidth';
import { formatCurrency } from '../_shared/utils';

export const Cart = () => {
    const { cartItems, totalPrice } = useCart();
    const { isMobile, isTablet } = useDeviceWidth();

    return (
        <Wrapper>
            <Header sticky={false}/>
            
            <CartWrapper
                style={{ width: isMobile ? '95%' : isTablet ? '90%' : '65%' }}
            >
                <CartItemsWrapper>
                    { 
                        cartItems?.length != 0 ?
                        cartItems?.map((product, i) => <CartItem key={i} cartItem={product} />):
                        <EmptyList caption="Products added to your cart will appear here" />
                    }
                </CartItemsWrapper>

                {
                    cartItems.length != 0 &&
                    <CartSummary>
                        <Flex justify="space-between" p={5}>
                            <span className="label">Total:</span>
                            <span className="total">${formatCurrency(totalPrice)}</span>
                        </Flex>

                        <CheckoutButton>Checkout</CheckoutButton>
                    </CartSummary>
                }
            </CartWrapper>
        </Wrapper>
    );
};

export default Cart;
