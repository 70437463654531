import React from 'react';
import { Flex, Skeleton } from '../../_shared/components';
import { useDeviceWidth } from '../../_shared/hooks';
import { Wrapper } from './styledComponents';

const ProductSkeleton = () => {
    const { isMobile } = useDeviceWidth();

    return (
        <Wrapper style={{ width: isMobile ? '100%' : 220 }}>
				<Skeleton 
					style={{ marginTop: isMobile ? -85 : -65, width: '100%', height: isMobile ? 400 : 310, borderRadius: 10 }}
				/>
				<Flex justify="space-between" mb={15} mt={-40} style={{ width: '98%' }}>
					<Skeleton 
						variant="text"
						style={{ width: '40%' }}
					/>
					<Skeleton 
						variant="text"
                        style={{ width: '20%' }}
					/>
				</Flex>
				<Skeleton 
					variant="text"
					style={{ marginBottom: 10, width: '98%' }}
				/>
				<Skeleton 
					variant="text"
					style={{ width: '98%' }}
				/>
			</Wrapper>
    );
};

export default ProductSkeleton;
