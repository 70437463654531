import React, { createContext, useEffect, useState } from 'react';
import Snackbar from '..';
import { SnackbarProps } from '../Snackbar';

// eslint-disable-next-line no-unused-vars
export const SnackbarContext = createContext<(args: SnackbarProps) => void>(() => null);

export default function SnackbarProvider({ children }: { children: any }) {
    const [snackbarProps, setSnackbarProps] = useState<SnackbarProps | null>();
    const [showSnackbar, setShowSnackbar] = useState(false);

    useEffect(() => {
        if (snackbarProps) {
            setShowSnackbar(true);
        }
    }, [snackbarProps]);

    return (
        <SnackbarContext.Provider value={setSnackbarProps}>
            {children}
            <Snackbar 
                open={showSnackbar} 
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                onClose={() => setShowSnackbar(false)}
                {...snackbarProps} 
            />
        </SnackbarContext.Provider>
    );

}
