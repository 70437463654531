import React from 'react';
import { useFormik } from 'formik';
import { Helmet } from 'react-helmet';
import FormField from '../_shared/components/FormField';
import { useDeviceWidth, useSnackbar } from '../_shared/hooks';
import { 
    BrandLogo, 
    FormCaption, 
    FormSubmitButton, 
    FormWrapper,  
    Wrapper 
} from './components/styledComponents';
import { useMutation, } from 'react-query';
import { resetPassword } from './services/apiService';
import { passwordResetSchema } from './services/validation';
import { useLocation } from 'react-router-dom';

export default function PasswordReset() {
    const { isDesktop } = useDeviceWidth();
    const { showSnackbar } = useSnackbar();

    const location = useLocation();

    const { mutateAsync, isLoading } = useMutation(resetPassword);
    
    const form = useFormik({
        validationSchema: passwordResetSchema,
        initialValues: {
            email: (location.state as { email: string}).email,
        },
        onSubmit: async values => {
            try {
                const { message } = await mutateAsync(values);

                showSnackbar({
                    message: message,
                    type: 'success',
                    duration: 'very-long',
                });

            } catch(error: any) {
                showSnackbar({
                    message: error?.response?.data?.message || 'Something Went Wrong',
                    type: 'error',
                });
            }  
        },
    });
    
    return (
        <Wrapper style={{ padding: 0, height: '80vh' }}>
            <Helmet>
                <title>Password Reset | Ceillo</title>
            </Helmet>

            <BrandLogo to="/">Ceillo</BrandLogo>

            <FormWrapper onSubmit={form.handleSubmit} style={{ width: isDesktop ? '30%' : '85%' }}>
                <FormCaption>Lorem ipsum, dolor sit amet consectetur adipisicing</FormCaption>

                <FormField 
                    value={form.values.email}
                    onChange={form.handleChange('email')}
                    label="Email" 
                    type="email" 
                    placeholder="example@somewhere.com" 
                    caption="Type the email address linked to your account and we'll send you password reset instructions. They might end up in your spam folder, so please check there as well."
                    error={form.touched.email && form.errors.email}
                />

                <FormSubmitButton 
                    loading={isLoading} 
                    type="submit"
                >
                    Submit
                </FormSubmitButton>
            </FormWrapper>
        </Wrapper>
    );
}
