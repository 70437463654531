import { useSnackbar } from ".";

export const useClipboard = () => {
    const { showSnackbar } = useSnackbar();

    function fallbackCopyTextToClipboard(text: string, successMessage: string) {
		const textArea = document.createElement("textarea");
		textArea.value = text;
		
		// Avoid scrolling to bottom
		textArea.style.top = "0";
		textArea.style.left = "0";
		textArea.style.position = "fixed";
 
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();

		try {
			const successful = document.execCommand('copy');
			if (successful) {
				showSnackbar({
					message: successMessage,
					type: 'info',
					duration: 'short'
				});
			}
		} catch (error) {
			console.error(error);
		}

		document.body.removeChild(textArea);
	}

    const copyTextToClipboard = async (text: string, successMessage: string) => {
		if(navigator.clipboard) {
			try {
				await navigator.clipboard.writeText(text);
				showSnackbar({
					message: successMessage,
					type: 'info',
					duration: 'short'
				});
			} catch(error) {
				console.log(error);
			}
		} else {
			fallbackCopyTextToClipboard(text, successMessage);
		}
	};

    return { copyTextToClipboard };
};
