import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from '../Authentication';
import { ContentWrapper, Flex, Header } from '../_shared/components';
import { useDeviceWidth } from '../_shared/hooks';
import Menu from './components/Menu';
import { Wrapper } from './components/styledComponent';

export const Dashboard = () => {
    const { isDesktop } = useDeviceWidth();
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            navigate('/');
        }
    }, []);

    return (
        <Wrapper>
            <Header sticky={false} />            
            <ContentWrapper>
                <Flex align="flex-start" justify={!isDesktop ? 'center' : undefined} px={15}>
                    {
                        isDesktop &&
                        <Menu />
                    }
                    <Outlet />
                </Flex>
            </ContentWrapper>
        </Wrapper>
    );
};

export default Dashboard;
