import React from 'react';
import { Flex, Skeleton } from '../../_shared/components';
import { useDeviceWidth } from '../../_shared/hooks';

export default function MyProductSkeleton() {
    const { isMobile } = useDeviceWidth();

    return (
        <Flex 
            mb={23} 
            justify="space-between" 
            style={{ 
                backgroundColor: '#ffffff', 
                maxWidth: 450, 
                borderRadius: 8,
            }}
        >
            <Skeleton variant="rectangular" style={{ width: isMobile ? '30%' : '25%', height: 120, borderTopLeftRadius: 7, borderBottomLeftRadius: 7 }}/>
            <Flex p={7} direction="column" align="flex-start" style={{ width: isMobile ? '65%' : '70%', height: 100 }}>
                <Skeleton variant="text" style={{ width: '75%' }} />
                <div style={{ marginTop: 'auto', width: '100%' }}>
                    <Skeleton variant="text" style={{ marginBottom: 2 }} />
                    <Skeleton variant="text" />
                </div>
                <Skeleton variant="text" style={{ width: '40%', marginTop: 10 }} />
            </Flex>
        </Flex>
    );
}
