import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getProduct } from '../Products/services/apiService';
import { Flex } from '../_shared/components';
import FormField, { ImageField, SelectField, TextArea } from '../_shared/components/FormField';
import { useDeviceWidth, useSnackbar } from '../_shared/hooks';
import { IProduct } from '../_shared/types';
import { getErrorMessage } from '../_shared/utils';
import { FormSubmitButton, FormWrapper } from './components/styledComponent';
import { editProduct, getCategories, postProduct } from './services/apiService';
import { postProductSchema } from './services/validation';
import { getChangedValues, getFilesFromRemoteImages } from './utils';

export default function ProductForm() {
    const { isDesktop } = useDeviceWidth();
    
    const { showSnackbar } = useSnackbar();
    
    const location = useLocation();
    
    const initialProduct = location.state as IProduct;
    
    const [initialProductConverted, setInitialProductConverted] = useState({});

	const { url_slug } = useParams<{ url_slug: string }>();

    const { data: categories } = useQuery('categories', getCategories, { initialData: [] });

    const { data: product } = useQuery<IProduct>('my-product', () => getProduct(url_slug as string), { initialData: initialProduct, enabled: !!url_slug });

    const { mutateAsync, isLoading } = useMutation(
        url_slug ? (args: IProduct) => editProduct(url_slug, args) : 
        postProduct
    );

    const navigate = useNavigate();

    const queryClient = useQueryClient();

    const form = useFormik({
        validationSchema: postProductSchema,
        initialValues: {
            name:  '',
            price: '',
            brand: '',
            description: '',
            stock: 1,
            images: [] as FileList | (File | null)[],
            category: '',
        },
        onSubmit: async values => {
            try {
                const _values = url_slug ? getChangedValues(initialProductConverted, values) : values;
                const { message } = await mutateAsync(_values);

                showSnackbar({
                    message: message,
                    type: 'success',
                });

                queryClient.invalidateQueries('products');
                queryClient.invalidateQueries('my-products');

                navigate('/dashboard');
            } catch(error: any) {
                showSnackbar({
                    message: getErrorMessage(error),
                    type: 'error',
                });
            }  
        },
    });

    useEffect(() => {
        (async () => {
            if (product) {
                const newProduct = {
                    ...product,
                    category: product.category?.id,
                    images: await getFilesFromRemoteImages(product.images) as unknown as FileList | (File | null)[]
                };
                form.setValues(newProduct);
                setInitialProductConverted(newProduct);
            }
        })();
    }, [product]);

    useEffect(() => {
        if(!url_slug) {
            form.resetForm();
        }
       return () => queryClient.removeQueries('my-product');
    }, [url_slug]);

    const handleImageChange = (image: File | null, index: number) => {
        const newImages = Array.from(form.values.images);
        if(image) {
            newImages[index] = image;
        } else {
            newImages.splice(index, 1);
        }
        form.setFieldValue('images', newImages);
    };

    return (
        <div>
            <Helmet>
                <title>{url_slug ? 'Edit' : 'Post'} Product | {url_slug ? product?.name : 'Ceillo'}</title>
            </Helmet>

            <FormWrapper onSubmit={form.handleSubmit} style={{ width: !isDesktop ? '100%' : 500 }}>
                <ImageField
                    label="Product's Images"
                    images={form.values.images}
                    onChangeImages={images => form.setFieldValue('images', images)} 
                    onChangeImage={handleImageChange}
                    error={form.touched.images && form.errors.images}
                    caption="Maximum size per image is 2MB"
                    edit={!!product}
                />

                <FormField 
                    value={form.values.name}
                    onChange={form.handleChange('name')}
                    label="Name" 
                    placeholder="Name" 
                    error={form.touched.name && form.errors.name}
                    style={{ width: '100%'}}
                />

                <Flex justify="space-between">
                    <FormField 
                        value={form.values.price}
                        onChange={form.handleChange('price')}
                        label="Price" 
                        placeholder="Price" 
                        type="number"
                        error={form.touched.price && form.errors.price}
                        style={{ width: '48%' }}
                    />

                    <FormField 
                        value={form.values.stock}
                        onChange={form.handleChange('stock')}
                        label="Stock" 
                        placeholder="Stock" 
                        type="number"
                        error={form.touched.stock && form.errors.stock}
                        style={{ width: '48%' }}
                    />
                </Flex>

                <SelectField
                    label="Category"
                    value={form.values.category}
                    placeholder="Category"
                    onChangeItem={form.handleChange('category')}
                    items={categories?.map((c: any) =>  ({ label: c.name, value: c.id }))}
                    error={form.touched.category && form.errors.category}
                />

                <FormField 
                    value={form.values.brand}
                    onChange={form.handleChange('brand')}
                    label="Brand" 
                    placeholder="Brand" 
                    error={form.touched.brand && form.errors.brand}
                    style={{ width: '100%'}}
                />

                <TextArea
                    value={form.values.description}
                    onChange={form.handleChange('description')}
                    label="Description" 
                    placeholder="Description" 
                    error={form.touched.description && form.errors.description}
                    style={{ width: '100%' }}
                />

                <FormSubmitButton type="submit" loading={isLoading}>
                    { url_slug ? 'Update' : 'Submit' }
                </FormSubmitButton>
            </FormWrapper>
        </div>
    );
}
