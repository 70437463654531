import hexToRgba from 'hex-to-rgba';
import React from 'react';
import styled from 'styled-components';
import EmptyImage from '../../assets/images/empty-list.png';

interface Props {
    imageSrc?: string;
    caption?: string;
    action?: React.ReactElement;
}

export default function EmptyList({ imageSrc, caption, action }: Props) {
    return (
        <EmptyListWrapper>
            <img src={imageSrc || EmptyImage} alt={caption} />
            <h4>{"Ouhh... it's empty in here"}</h4>
            {
                caption &&
                <p>{caption}</p>
            }
            {
                action &&
                action
            }
        </EmptyListWrapper>
    );
}

const EmptyListWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    'img': {
        width: 200,
        height: 200,
    },
    'p': {
        color: hexToRgba(theme.common.BLACK, 0.4),
        fontSize: 15.5,
        width: 230,
        textAlign: 'center',
        lineHeight: 1.6,
        margin: 0
    },
    'h4': {
        marginTop: 0,
        marginBottom: 5,
        color: hexToRgba(theme.common.BLACK, 0.7),
        lineHeight: 1.7,
    },
}));
