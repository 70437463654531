import axios from "axios";
import { getSecureAxios } from "../../Authentication/services/authService";
import { toFormData } from "../../Authentication/utils";
import { IProduct } from "../../_shared/types";

export const postProduct = async (args: IProduct) => {
    const secureAxios = getSecureAxios();

    const { data } = await secureAxios.post('/products/post-product/', toFormData(args));
    return data;
};

export const editProduct = async (url_slug: string, args: IProduct) => {
    const secureAxios = getSecureAxios();

    const { data } = await secureAxios.patch(`/products/product/${url_slug}/`, toFormData(args));
    return data;
};

export const getCategories = async () => {
    const secureAxios = getSecureAxios();

    const { data } = await secureAxios.get('/products/category/');
    return data;
};

export const getMyProducts = async (pageParam: number | undefined) => {
    const secureAxios = getSecureAxios();

    const { data } = await secureAxios.get(`/products/product/${pageParam ? '?page=' + pageParam : ''}`);
    return data;
};

export const getProducts = async (pageParam: number | undefined) => {
    const { data } = await axios.get(`/products/product/${pageParam ? '?page=' + pageParam : ''}`);
    return data;
};

export const deleteProduct = async (url_slug: string) => {
    const secureAxios = getSecureAxios();

    const { data } = await secureAxios.delete(`/products/product/${url_slug}`);
    return data;
};