import axios from "axios";
import { getSecureAxios } from "../../Authentication/services/authService";

export const getProducts = async (pageParam: number | undefined) => {
    const { data } = await axios.get(`/products/product/${pageParam ? '?page=' + pageParam : ''}`);
    return data;
};

export const getProduct = async (url_slug: string) => {
    const { data } = await axios.get(`/products/product/${url_slug}`);
    return data;
};

export const getUserProducts = async () => {
    const secureAxios = getSecureAxios();

    const { data } = await secureAxios.get(`/products/product/`);
    return data;
};