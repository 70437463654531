import React from 'react';
import { ICartItem } from '../../_shared/types/types';
import { ItemWrapper } from './styledComponent';
import { useDeviceWidth } from '../../_shared/hooks';
import Clamp from 'react-multiline-clamp';
import AddBoxRoundedIcon from'@mui/icons-material/AddBoxRounded';
import IndeterminateCheckBoxRoundedIcon from '@mui/icons-material/IndeterminateCheckBoxRounded';
import { useCart } from '../hooks/useCart';
import { Flex } from '../../_shared/components';
import { formatCurrency } from '../../_shared/utils';

export const Item = ({ cartItem }: { cartItem: ICartItem }) => {
    const { isMobile } = useDeviceWidth();
    const { removeFromCart, addToCart } = useCart();

    return (
        <ItemWrapper align="flex-start">
            <img
                src={cartItem.product.images[0].image_url}
                alt={cartItem.product.name}
            />

            <div style={{ width: isMobile ? '65%' : '70%', }}>
                <Clamp lines={1}><p className="title">{cartItem.product.name}</p></Clamp>

                <Flex justify="space-between" style={{ height: 30, marginTop: 12 }}>
                    <p className="price">${formatCurrency(cartItem.product.price)}</p>

                    <Flex>
                        <IndeterminateCheckBoxRoundedIcon 
                            sx={{ height: 26, width: 26 }} 
                            onClick={() => removeFromCart(cartItem.product)}
                        />
                        <span className='qty'>{cartItem.quantity}</span>
                        <AddBoxRoundedIcon
                            sx={{ height: 26, width: 26 }} 
                            onClick={()=> addToCart(cartItem.product)}
                        />
                    </Flex>
                </Flex>
            </div>
        </ItemWrapper>
    );
};

export default Item;
