export const toFormData = (data: any) => {
    const formData = new FormData();

    for (let key in data) {
        if (Array.isArray(data[key])) {
            for (let value of data[key]) {
                formData.append(key, value);
            }
        } else {
            formData.append(key, data[key]);
        }
    }

    return formData;
};