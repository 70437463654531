const reverseString = (s: string) => {
    return s.split('').reverse().join('');
};
  
export const formatCurrency = (c: string) => {
    let s =  Number(c).toFixed(2);

    if(s.length <= 3) {
        return s;
    }

    // store the decimals in 'rest' if any
    const decimal = s.split('.')[1];
    let rest = '';
    if (decimal !== '00') {
        rest = '.' + decimal;
    }

    // remove the decimals if any
    if (s.includes('.')) {
        s = s.substring(0, s.indexOf('.'));
    }

    // punctuate with a comma after every three characters starting from the end
    const result = reverseString(s).split('').map((char, index) => {
        if (index % 3 === 0 && index != 0) {
        return ',' + char;
        }
        return char;
    }).join('');

    return `${reverseString(result)}${rest}`;
};

export const getErrorMessage = (error: any) => {
    return error?.response?.data?.message || error.message || 'Something Went Wrong';
};
