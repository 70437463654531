import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeviceWidth, useSnackbar } from '../_shared/hooks';
import { 
    FormSubmitButton,
    VerifyEmailCaption, 
    VerifyEmailImage, 
    VerifyEmailImageWrapper, 
    VerifyEmailWrapper, 
    Wrapper 
} from './components/styledComponents';
import { useMutation, } from 'react-query';
import { resendEmail, verifyEmail } from './services/apiService';
import { saveToLocalStorage } from '../_shared/utils/storage';
import EmailIcon from '../_shared/assets/images/email.png';
import { CircularProgress } from '@mui/material';
import { Flex } from '../_shared/components';
import { getErrorMessage } from '../_shared/utils';

export default function PasswordReset() {
    const [showResendButton, setShowResendButton] = useState(false);

    const { isDesktop } = useDeviceWidth();
    const { showSnackbar } = useSnackbar();

    const { token } = useParams<{ token: string }>();
    const navigate = useNavigate();

    const { mutateAsync: _verifyEmail, isLoading: verifyLoading } = useMutation(() => verifyEmail(token as string));

    const { mutateAsync: _resendEmail, isLoading: resendLoading } = useMutation(() => resendEmail(token as string));

    const handleResendEmail = async () => {
        try {
            const { message } = await _resendEmail();

            showSnackbar({
                message: message,
                type: 'success',
            });
        } catch(error: any) {
            showSnackbar({
                message: error.message,
                type: 'error',
            });
        }  
    };

    useEffect(() => {
        (async () => {
            try {
                const { data, token, message } = await _verifyEmail();
                saveToLocalStorage('user', data);
                saveToLocalStorage('accessToken', token.access);
                saveToLocalStorage('refreshToken', token.refresh);

                showSnackbar({
                    message: message,
                    type: 'success',
                });

                navigate('/', { replace: true });
            } catch(error: any) {
                showSnackbar({
                    message: getErrorMessage(error),
                    type: 'error',
                });

                if (error?.response?.status === 401) {
                    setShowResendButton(true);
                }
            }  
        })();
    }, []);
    
    return (
        <Wrapper style={{ height: '86vh', backgroundColor: '#fafafa' }}>
            <Helmet>
                <title>Verify Email | Ceillo</title>
            </Helmet>

            <VerifyEmailWrapper style={{ width: isDesktop ? '30%' : '85%' }}>
                <VerifyEmailImageWrapper>
                    <VerifyEmailImage src={EmailIcon} />
                </VerifyEmailImageWrapper>

                <VerifyEmailCaption>
                    Et dolor vero ipsum est et magna invidunt sadipscing invidunt sadipscing.
                </VerifyEmailCaption>

                {
                    verifyLoading && 
                    <Flex>
                        <span style={{ fontSize: 18 }}>verifying</span>
                        <CircularProgress size={19} sx={{ marginLeft: 1.3 }} />  
                    </Flex>
                }
                {
                    showResendButton &&
                    <FormSubmitButton 
                        style={{ 
                            marginTop: 5, 
                            width: 150, 
                            height: 40,
                        }} 
                        loading={resendLoading} 
                        onClick={handleResendEmail}
                    >
                        Resend Email
                    </FormSubmitButton>
                }
            </VerifyEmailWrapper>
        </Wrapper>
    );
}
