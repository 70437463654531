import * as yup from 'yup';

export const loginSchema = yup.object({
    email: yup.string().required('Please enter your email').email("Not a valid email"),
    password: yup.string().required("Please enter your password")
});

export const signupSchema = yup.object({
    first_name: yup.string().required('Required'),
    last_name: yup.string().required('Required'),
    email: yup.string().required('Required').email("Not a valid email"),
    phone_number: yup.string().required('Required')
        .matches(/^(([2][3][3])|[0])([2]|[5])([4]|[5]|[9]|[0]|[6]|[7])\d{7}$/, 'Not a valid phone number'),
    university: yup.string().required('Required'),
    password: yup.string().required("Required")
        .min(8, 'Password must be at least 8 characters')
        .matches(/(?=.*\d)/, "Must contain a number"),
    password2: yup.string().required('Required')
        .oneOf([yup.ref('password'), null], "Passwords don't match"),
    agreed_to_terms: yup.boolean().oneOf([true, null], 'Agreement to Terms and Conditions is required'),
});

export const passwordResetSchema = yup.object({
    email: yup.string().required('Please enter your email').email("Not a valid email"),
});

export const passwordResetConfirmSchema = yup.object({
    password1: yup.string().required("Required")
        .min(8, 'Password must be at least 8 characters')
        .matches(/(?=.*\d)/, "Must contain a number"),
    password2: yup.string().required('Required')
        .oneOf([yup.ref('password1'), null], "Passwords don't match"),
    token: yup.string().required(),
});

export const merchantSchema = yup.object({
    brand: yup.string().required("Required"),
    id_card: yup.mixed()
        .required('Required')
        .test("fileSize", "The image is too large", (value: File) => {
            return value?.size <= 2000000;
        }),
    id_card_type: yup.string().required(),
});