import React from 'react';
import { Helmet } from 'react-helmet';
import { Header } from '../../_shared/components';
import { Wrapper } from './styledComponents';

export default function NotFound() {
    return (
        <>
            <Helmet>
                    <title>Page Not Found</title>
            </Helmet>
            <Header />
            <Wrapper>
                <h1>Ooops! Page Not Found</h1>
                <p style={{ fontSize: 20, textAlign: 'center' }}>Sorry, but the webpage you are trying to reach can not be found 😔</p>
            </Wrapper>
        </>
    );
}
