import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import hexToRgba from 'hex-to-rgba';
import styled from 'styled-components';

export const CustomButton = styled(Button)<{ secondary?: boolean; }>(({ theme, secondary, }) => ({
    backgroundColor: secondary ? hexToRgba(theme.SECONDARY, 0.5) : theme.PRIMARY,
    color: secondary ? theme.PRIMARY : theme.common.WHITE,
    ':hover': {
        backgroundColor: secondary ? hexToRgba(theme.SECONDARY, 0.2) : theme.PRIMARY,
    },
    textTransform: 'none',
    padding: '5px 10px',
    height: 40,
	borderRadius: 5,
    border: secondary ? `1px solid ${theme.PRIMARY}` : undefined,
}));

export const Spinner = styled(CircularProgress)<{ secondary?: boolean }>(({ theme, secondary }) => ({
    color: secondary ? theme.PRIMARY : theme.common.WHITE,
    marginLeft: 5,
}));
