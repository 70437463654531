import hexToRgba from 'hex-to-rgba';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../../_shared/components';

export const Wrapper = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '40px 0',
});

export const BrandLogo = styled(Link)(({ theme }) => ({
    fontSize: 35,
    color: hexToRgba(theme.PRIMARY, 0.9),
    fontFamily: 'Pacifico',
    letterSpacing: 2,
    textDecoration: 'none',
    width: '100%',
    textAlign: 'center',
}));

export const FormWrapper = styled.form({});

export const FormCaption = styled.p(({ theme }) => ({
    color: theme.PRIMARY,
    marginBottom: 25,
    marginTop: 5,
    fontSize: 17,
    padding: '0 10%',
    textAlign: 'center',
}));

export const FormSubmitButton = styled(Button)(({ theme }) => ({
   marginTop: 25,
   fontSize: 15,
   ':focus': {
        boxShadow: `0 0 0 4px ${hexToRgba(theme.PRIMARY, 0.2)}`,
    },
    width: '100%',
    height: 45,
}));

export const OAuthButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'transparent',
    color: hexToRgba(theme.common.BLACK, 0.8),
    border: `2px solid ${hexToRgba(theme.PRIMARY, 0.1)}`,
    width: '100%',
    marginBottom: 20,
    height: 47,
    fontSize: 14,
    ':focus': {
        boxShadow: `0 0 0 4px ${theme.SECONDARY}`,
    },
 }));


 export const OAuthButtonImage = styled.img({
    width: 23,
    marginRight: 10,
 });

export const ForgotPassword = styled(Link)(({ theme }) => ({
    color: theme.PRIMARY,
    fontSize: 15,
    position: 'relative',
    top: 5,
}));

export const BottomText = styled.span(({ theme }) => ({
    color: theme.PRIMARY,
    fontSize: 15,
    textAlign: 'center',
}));

export const SeparatorLine = styled.hr(({ theme }) => ({
    color: hexToRgba(theme.common.BLACK, 0.6),
    width: '100%',
}));

export const SeparatorText = styled.p(({ theme }) => ({
    color: hexToRgba(theme.common.BLACK, 0.6),
    padding: '0 15px',
}));

export const VerifyEmailWrapper = styled.div(({ theme }) => ({
    backgroundColor: theme.common.WHITE, 
    textAlign: 'center', 
    padding: '25px 20px',
    borderRadius: 5, 
    border: `2px solid ${theme.SECONDARY}`,
    position: 'relative',
    top: -20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

export const VerifyEmailImage = styled.img({
    width: 80, 
});

export const VerifyEmailImageWrapper = styled.div(({ theme }) => ({
    backgroundColor: hexToRgba(theme.SECONDARY, 0.6),
    padding: 30,
    borderRadius: '50%',
    width: 90,
    height: 90,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export const VerifyEmailCaption = styled.p(({ theme }) => ({
    color: hexToRgba(theme.common.BLACK, 0.5), 
    lineHeight: '23px',
    marginBottom: 20,
    fontSize: 15,
    maxWidth: 350,
}));