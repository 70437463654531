import React, { TextareaHTMLAttributes } from 'react';
import { 
    FormCaptionText, 
    FormErrorText, 
    FormFieldWrapper, 
    FormInputWrapper, 
    FormLabel,
    TextAreaInput, 
} from './styledComponents';

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    label?: string;
    error?: any;
    caption?: string;
}

export default function TextArea({ value, label, error, style, caption, ...rest }: Props) {
    return (
        <FormFieldWrapper style={style}>
            {
                label && 
                <FormLabel>{label}</FormLabel>
            }
            <FormInputWrapper error={!!error}>
                <TextAreaInput value={value} {...rest} />
            </FormInputWrapper>
            {
                error &&
                <FormErrorText>{error}</FormErrorText>
            }
            {
                caption &&
                <FormCaptionText>{caption}</FormCaptionText>
            }
        </FormFieldWrapper>
    );
}
