import React, { 
    ChangeEvent, 
    DragEvent, 
    InputHTMLAttributes, 
    useEffect, 
    useState 
} from 'react';
import { 
    FormCaptionText, 
    FormErrorText, 
    FormFieldWrapper, 
    FormInput, 
    FormLabel,
    ClearButton,
    ImageFieldButton, 
    ImageFieldImage, 
    ImageInputWrapper, 
    ImagePlaceholder, 
    ImagesWrapper,
    ImagePlaceholderButton,
    ImageRemoveButton,
    ImageControlsWrapper,
    ImageEditButton
} from './styledComponents';
import ImagesPlaceholder from '../../../assets/images/images.png';
import { Flex } from '../..';
import { ClearRounded } from '@mui/icons-material';
import Constants from '../../../Constants';
import hexToRgba from 'hex-to-rgba';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    error?: any;
    caption?: string;
    images: FileList | (File | null)[];
    edit: boolean;
    // eslint-disable-next-line no-unused-vars
    onChangeImages: (images: FileList | (File | null)[]) => void;
    // eslint-disable-next-line no-unused-vars
    onChangeImage: (image: File | null, index: number) => void;
}

export default function ImageField(props: Props) {
    const { 
        images, 
        label, 
        error, 
        style, 
        caption,
        onChangeImages,
        onChangeImage,
        ...rest 
    } = props;

    const { theme } = Constants.ui;

    const [_images, setImages] = useState<FileList | (File | null)[]>([...Array.from(images)] as unknown as FileList | (File | null)[]);
    const [replaceIndex, setReplaceIndex] = useState(0);

    useEffect(() => {
        if(images.toString() !== _images.toString())
            onChangeImages(Array.from(_images).slice(0, 4));
    }, [_images]);

    const handleImageChange = (event: ChangeEvent<any>, single?: boolean) => {
        if (single) {
            setImages(prevImages => [...Array.from(prevImages), event.target.files[0]]);
        } else {
            setImages(event.target.files);
        }
    };

    const handleImageReplace = (event: ChangeEvent<any>) => {
        if (event.target.files.length) {
            onChangeImage(event.target.files[0], replaceIndex);
        }
    };

    const handleImagesDrop = (event: DragEvent<any>, single?: boolean) => {
        event.preventDefault();

        if (event.dataTransfer.items) {
          // Use DataTransferItemList interface to access the file(s)
          const _images: any[] = [];

          for (let i = 0; i < event.dataTransfer.items.length; i++) {
            if (event.dataTransfer.items[i].kind === 'file') {
              _images.push(event.dataTransfer.items[i].getAsFile());
            }
          }
          
          if (single) {
            setImages(prevImages => [...Array.from(prevImages), _images[0]]);
          } else {
            setImages(_images);
          }
        } else {
          // Use DataTransfer interface to access the file(s)
          setImages(event.dataTransfer.files);
        }
    };

    useEffect(() => {
        setImages(images);
    }, [images]);

    return (
        <FormFieldWrapper style={style}>
            {
                label && 
                <FormLabel style={{ marginBottom: 10 }}>{label}</FormLabel>
            }

            {
                _images.length > 0 ?
                <ImagesWrapper>
                    {
                        Array.from(images).map((image, i) =>
                            <div key={i} style={{ width: i === 0 ? '100%' : '25%' }}>
                                <ImageControlsWrapper first={i === 0}>
                                    <ImageRemoveButton first={i === 0} onClick={() => onChangeImage(null, i)} />
                                    <ImageEditButton onClick={() => setReplaceIndex(i)} htmlFor="image-replace" first={i === 0} />
                                </ImageControlsWrapper>
                                <ImageFieldImage 
                                    first={i === 0}
                                    src={URL.createObjectURL(image as Blob | MediaSource)}
                                />
                           </div>
                        )
                    }
                    {
                        Array.from({ length: 4 - _images.length}, (_, i) => 
                            <ImagePlaceholder 
                                key={i} 
                                onDrop={event => handleImagesDrop(event, true)} 
                                onDragOver={e => e.preventDefault()}
                            >
                                {
                                    i === 0 ?
                                    <div>
                                        <ImagePlaceholderButton htmlFor="image">
                                            Choose
                                        </ImagePlaceholderButton>
                                        <span>or drop an image</span>
                                    </div> :
                                    `Image ${i + _images.length + 1}`
                                }
                            </ImagePlaceholder>)

                    }
                </ImagesWrapper> :

                <ImageInputWrapper onDrop={handleImagesDrop} onDragOver={e => e.preventDefault()}>
                    <img src={ImagesPlaceholder} />
                    <ImageFieldButton htmlFor="images">Choose</ImageFieldButton>
                    <span>or drop your images here</span>
                    <FormInput 
                        hidden 
                        id="images" 
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={handleImageChange}
                        {...rest}
                    />
                </ImageInputWrapper>
            }

            {
                error &&
                <FormErrorText>{error}</FormErrorText>
            }
            <Flex justify="space-between" align="flex-start" mt={10} mb={30}>
                {
                    caption &&
                    <FormCaptionText>{caption}</FormCaptionText>
                }
                {
                    _images.length > 0 &&
                    <ClearButton 
                        onClick={() => setImages([])}
                        endIcon={<ClearRounded sx={{ color: hexToRgba(theme.common.BLACK, 0.6) }} />}
                    >
                        Clear
                    </ClearButton>
                }
            </Flex>
            <FormInput 
                hidden 
                id="image" 
                type="file"
                accept="image/*"
                onChange={event => handleImageChange(event, true)}
            />
            <FormInput 
                hidden 
                id="image-replace" 
                type="file"
                accept="image/*"
                onChange={event => handleImageReplace(event)}
            />
        </FormFieldWrapper>
    );
}
