import React, { useState, useEffect, useRef } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useAuth } from '../../Authentication';
import {ICartItem } from '../../_shared/types/types';
import { getErrorMessage } from '../../_shared/utils';
import { saveToLocalStorage, getFromLocalStorage } from '../../_shared/utils/storage';
import { getCart, setCart } from '../services/apiServices';


interface ICartContext {
    cartCount: number | null;
    cartItems: ICartItem[];
    setCartItems: React.Dispatch<React.SetStateAction<ICartItem[]>>;
    totalPrice: string;
}

export const CartContext = React.createContext<ICartContext>({
    cartCount: null,
    cartItems: [],
    setCartItems: () => [],
    totalPrice: '0'
});

export default function CartProvider({ children }: { children: any }) {
    const [cartItems, setCartItems] = useState<ICartItem[]>([]);
    const [cartCount, setCartCount] = useState<number | null>(0);
    const [totalPrice, setTotalPrice] = useState<string>('0');

    const isFirstRender = useRef(true);

    const { user } = useAuth();

    useQuery(
        'cart', 
        getCart, 
        { 
            enabled: !!user,
            onSuccess: data => {
                setCartItems(data);
            }
        }
    );

    const { mutateAsync: _setCart } = useMutation(setCart);

    useEffect(() => {
        const cartitems = getFromLocalStorage("cartitem");

        setCartItems(cartitems);

        isFirstRender.current = false;
    }, []);

    useEffect(() => {
        setCartCount(cartItems?.reduce((acc:number , item:ICartItem)=>(acc + item.quantity), 0));
        saveToLocalStorage("cartitem", cartItems);

        // TODO: prevent this from running on first render
        (async () => {
            if(user && !isFirstRender.current) {
                try {
                    await _setCart(cartItems?.map(cartItem => ({
                        url_slug: cartItem.product.url_slug,
                        quantity: cartItem.quantity
                    })) as any);
                } catch(err) {
                    console.log(getErrorMessage(err));
                }
            }
        })();

        setTotalPrice(
            cartItems?.reduce((acc:number, item) => (acc + (item.quantity * parseInt(item.product.price))), 0)
            .toString()
        );

    }, [cartItems]);

    return (
        <CartContext.Provider
            value={{ cartCount, cartItems, setCartItems, totalPrice }}
        >
            {children}
        </CartContext.Provider>
    );
}
