import React from 'react';
import { ButtonProps } from '@mui/material';
import { CustomButton, Spinner } from './components/styledComponents';

interface Props extends ButtonProps {
    children: any;
    secondary?: boolean;
    loading?: boolean;
    spinnerSize?: number;
}

export default function Button(props: Props) {
    const { 
        children, 
        loading, 
        secondary, 
        spinnerSize, 
        ...rest 
    } = props;

    return (
        <CustomButton secondary={secondary} {...rest}>
            {
                loading ?
                <Spinner secondary={secondary} size={spinnerSize || 21} /> :
                children
            }
        </CustomButton>
    );
}
