import { Avatar } from '@mui/material';
import hexToRgba from 'hex-to-rgba';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Flex } from '../..';
import IconButton from '@mui/material/IconButton';

export const HeaderWrapper = styled(Flex)<{ sticky: boolean }>(({ theme, sticky }) => ({
    borderBottom: `1px solid ${hexToRgba(theme.PRIMARY, 0.15)}`,
    position: sticky ? 'sticky' : undefined,
    height: 35,
    top: 0,
    backgroundColor: theme.common.WHITE,
    marginBottom: 20,
    zIndex: 2
}));

export const BrandLogo = styled(Link)(({ theme }) => ({
    fontSize: 27,
    color: hexToRgba(theme.PRIMARY, 0.9),
    fontFamily: 'Pacifico',
    letterSpacing: 2,
    textDecoration: 'none',
}));

export const Navbar = styled(Flex)({});

export const NavItem = styled(Link)(({ theme }) => ({
    color: theme.PRIMARY,
    fontSize: 17,
    textDecoration: 'none',
    marginRight: 30,
}));

export const LoginButton = styled(Link)(({ theme }) => ({
    color: hexToRgba(theme.PRIMARY, 0.8),
    fontSize: 15,
    textDecoration: 'none',
    border: `1px solid ${theme.PRIMARY}`,
    padding: '8px 12px',
    borderRadius: 3,
    backgroundColor: hexToRgba(theme.SECONDARY, 0.7),
}));

export const SignupButton = styled(Link)(({ theme }) => ({
    color: theme.BACKGROUND,
    backgroundColor: theme.PRIMARY,
    fontSize: 15,
    textDecoration: 'none',
    padding: '9px 13px',
    borderRadius: 5,
    marginLeft: 15,
}));

export const MenuButton = styled(Button)({
    fontSize: 17,
    border: 'none',
    backgroundColor: 'transparent',
});

export const HeaderAvatar = styled(Avatar)(({ theme }) => ({
    height: 24, 
    width: 24, 
    fontSize: 17, 
    marginRight: 6,
    backgroundColor: theme.SECONDARY,
    color: theme.common.BLACK,
    border: `1px solid ${hexToRgba(theme.common.BLACK, 0.2)}`,
}));

export const AddProductButton = styled(Button)({
    height: 35,
    borderRadius: 4,
});

export const AddProductIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.SECONDARY,
    marginLeft: 5,
}));
