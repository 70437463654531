import React from 'react';
import { ClearRounded, EditOutlined } from '@mui/icons-material';
import { IconButton, Select } from '@mui/material';
import hexToRgba from 'hex-to-rgba';
import styled from 'styled-components';
import { Button, Flex } from '../..';

export const FormFieldWrapper = styled.div({
    marginTop: 15,
});

export const FormInputWrapper = styled(Flex)<{ error?: boolean }>(({ theme, error }) => ({
    ':focus-within': {
        boxShadow: `0 0 0 4px ${theme.SECONDARY}`,
    },
    boxShadow: error ? `0 0 0 3px ${hexToRgba(theme.status.DANGER, 0.2)}` : undefined,
    border: `2px solid ${hexToRgba(theme.PRIMARY, 0.2)}`,
    borderRadius: 5,
    padding: 1,
    backgroundColor: theme.common.WHITE,
}));

export const FormLabel = styled.label(({ theme }) => ({
    color: theme.common.BLACK,
    textOverflow: 'ellipsis',
    display: 'inline-block',
    marginBottom: 5,
}));

export const FormInput = styled.input(({ theme }) => ({
    width: '100%',
    color: theme.common.BLACK,
    fontSize: 15,
    height: 40,
    padding: '0 10px',
    border: 'none',
    backgroundColor: 'transparent',
    outline: 'none',
    '::placeholder': {
        color: hexToRgba(theme.common.BLACK, 0.4),
        fontSize: 15,
    }
}));

export const TextAreaInput = styled.textarea(({ theme }) => ({
    width: '100%',
    color: theme.common.BLACK,
    fontSize: 16,
    height: 100,
    fontFamily: 'Euclid',
    padding: '5px 10px',
    border: 'none',
    backgroundColor: 'transparent',
    outline: 'none',
    '::placeholder': {
        color: hexToRgba(theme.common.BLACK, 0.4),
        fontSize: 17,
        fontFamily: 'Euclid',
    }
}));

export const Checkbox = styled.input(({ theme }) => ({
    color: theme.common.BLACK,
    marginRight: 8,
    cursor: 'pointer',
    height: 15,
    width: 15,
    filter: 'grayscale(100%)',
}));

export const FormSubmitButton = styled.button(({ theme }) => ({
   backgroundColor: theme.PRIMARY,
   color: theme.SECONDARY,
   border: 'none',
   width: '100%',
   borderRadius: 5,
   padding: 13,
   marginTop: 20,
   fontSize: 15,
   ':focus': {
        boxShadow: `0 0 0 4px ${hexToRgba(theme.PRIMARY, 0.2)}`,
    },
}));

export const FormErrorText = styled.span(({ theme }) => ({
    display: 'block',
    color: theme.status.DANGER,
    padding: '5px 2px',
    fontSize: 14,
    postion: 'relative',
}));

export const FormCaptionText = styled.span(({ theme }) => ({
    color: hexToRgba(theme.common.BLACK, 0.7),
    fontSize: 14,
    position: 'relative',
    top: 4,
}));

export const ShowPasswordButton = styled.div(({ theme }) => ({
    color: hexToRgba(theme.common.BLACK, 0.3),
    outline: 'none',
    border: 'none',
    backgroundColor: 'transparent',
    fontSize: 14,
    padding: '6px 7px',
    cursor: 'pointer',
}));

export const IdCard = styled.img({
    height: 45,
    width: 80,
    borderRadius: 5,
    marginLeft: 10,
});

export const IdCardInputLabel = styled.label(({ theme }) => ({
    cursor: 'pointer',
    backgroundColor: theme.SECONDARY,
    padding: '12px 9px',
    borderRadius: 5,
    marginLeft: 10,
    flex: 1,
    textAlign: 'center',
    border: `1px dashed ${hexToRgba(theme.PRIMARY, 0.5)}`
}));

export const CustomSelect = styled(Select)({
    maxHeight: 39,
    flex: 1,
    'fieldset': {
        border: 'none',
    },
});

export const ImageInputWrapper = styled.div(({ theme }) => ({
    padding: 10,
    border: `2px dashed ${hexToRgba(theme.common.BLACK, 0.1)}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: 200,
    borderRadius: 5,
    'span': {
        color: hexToRgba(theme.common.BLACK, 0.6),
        marginTop: 10,
        textAlign: 'center'
    },
    'img': {
        width: 70,
        position: 'relative',
        bottom: 15,
    }
}));

export const ImageFieldButton = styled.label(({ theme }) => ({
    backgroundColor: theme.PRIMARY,
    color: theme.common.WHITE,
    padding: '5px 10px',
    borderRadius: 3,
    cursor: 'pointer',
}));

export const ImagesWrapper = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
});

export const ImageFieldImage = styled.img<{ first?: boolean }>(({ first }) => ({
    height: first ? 300 : 80,
    width: '100%',
    marginBottom: first ? 10 : undefined,
    borderRadius: 5,
    objectFit: 'cover',
}));

export const ImagePlaceholder = styled.div(({ theme }) => ({
    width: '25%',
    height: 80,
    backgroundColor: hexToRgba(theme.common.BLACK, 0.02),
    borderRadius: 5,
    border: `2px dashed ${hexToRgba(theme.common.BLACK, 0.06)}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: hexToRgba(theme.common.BLACK, 0.5),
    'div': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    'span': {
        textAlign: 'center',
        fontSize: 14,
        width: '85%',
        marginTop: 3
    }
}));

export const ImagePlaceholderButton = styled.label(({ theme }) => ({
    backgroundColor: theme.PRIMARY,
    color: theme.common.WHITE,
    padding: '3px 5px',
    borderRadius: 3,
    cursor: 'pointer',
    fontSize: 13,
}));

export const ClearButton = styled(Button)(({ theme }) => ({
    height: 30,
    backgroundColor: hexToRgba(theme.common.BLACK, 0.03),
    color: hexToRgba(theme.common.BLACK, 0.8),
    border: `1px solid ${hexToRgba(theme.common.BLACK, 0.1)}`,
    ':hover': {
        backgroundColor: theme.SECONDARY,
    }
}));

export const ImageRemoveButton = styled(
    props => <IconButton {...props}>
        <ClearRounded 
            style={{ 
                fontSize: props.first ? 23 : 19 
            }} 
        />
    </IconButton>
)<{ first: boolean }>(({ theme, first }) => ({
    backgroundColor: hexToRgba(theme.common.WHITE, 0.5),
    cursor: 'pointer',
    width: first ? 35 : 27,
    height: first ? 35 : 27,
    backdropFilter: 'blur(5px)',
    marginBottom: first ? 10 : 6,
    ':hover': {
        backgroundColor: hexToRgba(theme.common.WHITE, 0.25),
    }
}));

export const ImageEditButton = styled(
    props => 
        <IconButton {...props}>
            <label htmlFor={props.htmlFor} style={{ cursor: 'pointer' }}>
                <EditOutlined 
                    style={{ 
                        fontSize: props.first ? 18 : 16,
                        marginBottom: props.first ? 0 : 0.5,
                    }} 
                />
            </label>
        </IconButton>
)<{ first: boolean }>(({ theme, first }) => ({
    backgroundColor: hexToRgba(theme.common.WHITE, 0.5),
    cursor: 'pointer',
    width: first ? 35 : 27,
    height: first ? 35 : 27,
    backdropFilter: 'blur(5px)',
    ':hover': {
        backgroundColor: hexToRgba(theme.common.WHITE, 0.25),
    }
}));

export const ImageControlsWrapper = styled.div<{ first: boolean }>(({ first }) => ({
    float: 'right',
    marginBottom: first ? -80 : -70,
    marginRight: first ? 7 : 4,
    position: 'relative',
    top: first ? 7 : 4,
    display: 'flex',
    flexDirection: 'column'
}));