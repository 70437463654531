import { useState, useLayoutEffect, useEffect } from 'react';
import Constants from '../Constants';

export const useDeviceWidth = () => {
    const initialWidth = window.innerWidth;
    const TABLET_MIN_WIDTH = Constants.ui.device.TABLET_MIN_WIDTH;
    const destopMinWidth = Constants.ui.device.DESKTOP_MIN_WIDTH;

    const [width, setWidth] = useState(initialWidth);
    const [isMobile, setIsMobile] = useState(initialWidth < TABLET_MIN_WIDTH);
    const [isTablet, setIsTablet] = useState(initialWidth >= TABLET_MIN_WIDTH && initialWidth < destopMinWidth);
    const [isDesktop, setIsDesktop] = useState(initialWidth >= destopMinWidth);

    useLayoutEffect(() => {
        window.addEventListener('resize', () => setWidth(window.innerWidth));
        return () => window.removeEventListener('resize', () => setWidth(window.innerWidth));
    });

    useEffect(() => {
        setIsMobile(width < TABLET_MIN_WIDTH);
        setIsTablet(width >= TABLET_MIN_WIDTH && width < destopMinWidth);
        setIsDesktop(width >= destopMinWidth);
    }, [width]);
    
    return { width, isMobile, isTablet, isDesktop };
};