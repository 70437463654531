import React, { useEffect, useRef } from 'react';

interface Props extends Omit<Partial<IntersectionObserver>, 'disconnect'|'observe'|'takeRecords'|'unobserve'> {
	callback: () => void;
	children?: any;
}

export default function TriggerOnView({ callback, children, ...rest }: Props) {
    const spanRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
		let observer = new IntersectionObserver(entries => {
			if(entries[0].isIntersecting === true) {
				callback();
			}
		}, { ...rest });
	
		observer.observe(spanRef.current as Element);
	}, []);	

    return <div ref={spanRef} style={{ height: 1 }}>{children && children}</div>;
}
