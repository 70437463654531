import { getSecureAxios } from "../../Authentication/services/authService";
import { ICartItem } from "../../_shared/types";

export const getCart = async () => {
    const secureAxios = getSecureAxios();

    const { data } = await secureAxios.get('/cart/');
    return data?.data?.items || [];
};

export const setCart = async (cart_items: ICartItem[]) => {
    const secureAxios = getSecureAxios();

    const { data } = await secureAxios.post('/cart/', { cart_items });
    return data;
};