import { useContext } from "react";
import { SnackbarContext } from "../components/Snackbar";
import { SnackbarProps } from "../components/Snackbar/Snackbar";

export function useSnackbar() {
    const setSnackbarProps = useContext(SnackbarContext);

    const showSnackbar = (args: SnackbarProps) => {
        setSnackbarProps(args);
    };

    return {  showSnackbar };
}
