import React from 'react';
import { useFormik } from 'formik';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import FormField from '../_shared/components/FormField';
import Constants from '../_shared/Constants';
import { useDeviceWidth, useSnackbar } from '../_shared/hooks';
import { 
    BrandLogo, 
    FormCaption, 
    FormSubmitButton, 
    FormWrapper,  
    Wrapper 
} from './components/styledComponents';
import { useMutation, } from 'react-query';
import { resetPasswordConfirm } from './services/apiService';
import { passwordResetConfirmSchema } from './services/validation';

export default function PasswordResetConfirm() {
    const { isDesktop } = useDeviceWidth();
    const { routes } = Constants;
    const { showSnackbar } = useSnackbar();

    const { token } = useParams<{ token: string }>();
    const navigate = useNavigate();

    const { mutateAsync, isLoading } = useMutation(resetPasswordConfirm);
    
    const form = useFormik({
        validationSchema: passwordResetConfirmSchema,
        initialValues: {
            password1: '',
            password2: '',
            token: token as string
        },
        onSubmit: async values => {
            try {
                const { message } = await mutateAsync(values);

                showSnackbar({
                    message,
                    type: 'success',
                });

                navigate(routes.LOGIN);
            } catch(error: any) {
                showSnackbar({
                    message: error?.response?.data?.message || 'Something Went Wrong',
                    type: 'error',
                });
            }
        },
    });
    
    return (
        <Wrapper style={{ padding: 0, height: '80vh' }}>
            <Helmet>
                <title>Confirm Password Reset | Ceillo</title>
            </Helmet>

            <BrandLogo to="/">Ceillo</BrandLogo>

            <FormWrapper onSubmit={form.handleSubmit} style={{ width: isDesktop ? '30%' : '85%'}}>
                <FormCaption>Lorem ipsum, dolor sit amet consectetur adipisicing</FormCaption>

                <FormField 
                    value={form.values.password1}
                    onChange={form.handleChange('password1')}
                    label="Password" 
                    placeholder="at least 8 characters" 
                    type="password" 
                    error={form.touched.password1 && form.errors.password1}
                />

                <FormField
                    value={form.values.password2}
                    onChange={form.handleChange('password2')}
                    label="Confirm Password" 
                    placeholder="at least 8 characters" 
                    type="password" 
                    error={form.touched.password2 && form.errors.password2}
                />
                
                <FormSubmitButton 
                    loading={isLoading}
                    type="submit"
                >
                    Submit
                </FormSubmitButton>
            </FormWrapper>
        </Wrapper>
    );
}
