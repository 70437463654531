import React from 'react';
import styled from 'styled-components';
import { Flex } from '../../_shared/components';
import { IProduct } from '../../_shared/types';
import Clamp from 'react-multiline-clamp';
import hexToRgba from 'hex-to-rgba';
import { useDeviceWidth } from '../../_shared/hooks';
import { useNavigate } from 'react-router-dom';
import { formatCurrency } from '../../_shared/utils';

interface Props {
    product: IProduct;
}

export default function MyProduct({ product }: Props) {
    const { isMobile } = useDeviceWidth();

    const navigate = useNavigate();

    return (
        <MyProductWrapper onClick={() => navigate(`edit-product/${product.url_slug}`, { state: product })}>
            <img 
                src={product.images[0]?.image_url} 
                alt={product.name}
                style={{ width: isMobile ? '30%' : '25%' }}
            />
            <div style={{ width: isMobile ? '65%' : '70%' }}>
                <Clamp lines={1}>
                    <p style={{ fontSize: 16.5 }}>{product.name}</p>
                </Clamp>
                <Clamp lines={2}>
                    <p style={{ fontSize: 14.5, lineHeight: 1.6 }}>{product.description}</p>
                </Clamp>
                <Flex mt={8}>
                    <Price>
                        ₵
                        <span>
                            {formatCurrency(product.price)}
                        </span>
                    </Price>
                    {/* <Status>active</Status> */}
                    {
                        product.stock > 1 &&
                        <Stock>{product.stock}</Stock>
                    }
                </Flex>
            </div>
        </MyProductWrapper>
    );
}

const MyProductWrapper = styled(Flex)(({ theme }) => ({
    backgroundColor: theme.common.WHITE,
    marginBottom: 23,
    // padding: 7,
    borderRadius: 7,
    cursor: 'pointer',
    maxWidth: 450,
    boxShadow: `2px 2px 3px 1px ${hexToRgba(theme.common.BLACK, 0.027)}`,
    ':hover': {
        boxShadow: `3px 3px 10px 3px ${hexToRgba(theme.common.BLACK, 0.03)}`,
	},
    'img': {
        height: 120,
        borderTopLeftRadius: 7,
        borderBottomLeftRadius: 7,
        objectFit: 'cover',
        width: 75
    },
    '> div': {
        height: 105,
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 'auto',
        padding: 7,
        paddingBottom: 3,
    },
    'p': {
        margin: 0
    },
    '> div p:nth-child(2)': {
        color: hexToRgba(theme.common.BLACK, 0.6),
        fontSize: 15,
        lineHeight: 1.4,
        marginTop: 6,
    },
}));

// const Status = styled.span(({ theme }) => ({
//     backgroundColor: hexToRgba(theme.status.SUCCESS, 0.08),
//     padding: '2px 10px',
//     paddingTop: 1,
//     fontSize: 14,
//     color: '#036400',
//     borderRadius: 10,
// }));

const Stock = styled.span(({ theme }) => ({
    backgroundColor: hexToRgba(theme.SECONDARY, 0.7),
    padding: '0.5px 9px',
    paddingTop: 1,
    fontSize: 13,
    color: '#8b4100',
    borderRadius: 10,
    marginLeft: 15
}));

const Price = styled.span(({ theme }) => ({
	color: hexToRgba(theme.PRIMARY, 0.7),
	fontSize: 15,
}));
