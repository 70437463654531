import React, { InputHTMLAttributes } from 'react';
import { Flex } from '../..';
import { 
    FormErrorText, 
    FormFieldWrapper, 
    FormLabel,
    Checkbox
} from './styledComponents';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label?: string | React.ReactNode;
    error?: any;
}

export default function CheckboxField({ value, label, error, style, ...rest }: Props) {    
    return (
        <FormFieldWrapper style={style}>
            <Flex align="center">
                <Checkbox value={value} type="checkbox" {...rest} />
                {
                    label && 
                    <FormLabel style={{ marginBottom: 0}}>{label}</FormLabel>
                }
            </Flex>
            {
                error && 
                <FormErrorText>{error}</FormErrorText>
            }
        </FormFieldWrapper>
    );
}
