import React from 'react';
import { useFormik } from 'formik';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import FormField, { IdCardField } from '../_shared/components/FormField';
import { useSnackbar } from '../_shared/hooks';
import {
    FormCaption,
    FormSubmitButton, 
    FormWrapper, 
} from './components/styledComponents';
import { useMutation} from 'react-query';
import { becomeAMerchant } from './services/apiService';
import { useAuth } from '.';
import { MerchantData, User } from '../_shared/types';
import { Modal, Box, ModalProps } from '@mui/material';
import { getErrorMessage } from '../_shared/utils';
import { merchantSchema } from './services/validation';

interface Props extends Omit<ModalProps, 'children'> {
    onClose: () => void;
}

export default function Merchant(props: Props) {
    const { onClose, ...rest } = props;

    const { showSnackbar } = useSnackbar();

    const navigate = useNavigate();

    const { user, setUser } = useAuth();

    const { mutateAsync, isLoading } = useMutation(becomeAMerchant);
    
    const form = useFormik({
        validationSchema: merchantSchema,
        initialValues: {
            brand: '',
            id_card: {} as File,
            id_card_type: 'Student' as MerchantData['id_card_type']
        },
        onSubmit: async values => {
            try {
                const { message, merchant } = await mutateAsync(values);
                setUser({ ...user, merchant } as User);

                showSnackbar({
                    message: message,
                    type: 'success',
                });

                onClose();
                navigate('/dashboard');
            } catch(error: any) {
                showSnackbar({
                    message: getErrorMessage(error),
                    type: 'error',
                });
            }
        },
    });
    
    return (
        <Modal onClose={onClose} {...rest}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: 400,
                bgcolor: 'white',
                border: '1px solid gray',
                borderRadius: 1.2,
                boxShadow: 24,
                p: 4,
            }}>
                <Helmet>
                    <title>Become a Merchant | Ceillo</title>
                </Helmet>

                <FormWrapper onSubmit={form.handleSubmit} encType="multipart/form-data">
                    <FormCaption>Lorem ipsum, dolor sit amet consectetur adipisicing</FormCaption>

                    <FormField 
                        value={form.values.brand}
                        onChange={form.handleChange('brand')}
                        label="Brand" 
                        type="text" 
                        placeholder="Your brand's name" 
                        error={form.touched.brand && form.errors.brand}
                    />

                    <IdCardField
                        idCard={form.values.id_card}
                        idCardType={form.values.id_card_type}
                        onChangeCard={image => form.setFieldValue('id_card', image)}
                        onChangeType={form.handleChange('id_card_type')}
                        label="ID Card"
                        error={form.touched.id_card && form.errors.id_card}
                    />

                    <FormSubmitButton 
                        type="submit"
                        loading={isLoading} 
                    >
                        Submit
                    </FormSubmitButton>

                </FormWrapper>
            </Box>
        </Modal>
    );
}
