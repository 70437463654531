const Constants = {
    app: {
        BASE_URL: 'https://ceillo-app.herokuapp.com',
        // BASE_URL: 'http://localhost:8000',
    },
    ui: {
        device: {
            TABLET_MIN_WIDTH: 510,
            DESKTOP_MIN_WIDTH: 1024,
        },
        theme: {
            status: {
                SUCCESS: '#388e3c',
                DANGER: '#ca0b00',
                WARNING: '#ffa726',
            },
            common: {
                WHITE: '#ffffff',
                BLACK: '#000000',
            },
            PRIMARY: '#262627',
            SECONDARY: '#f5eee8',
            TERTIARY: '#1e90ff',
            BACKGROUND: '#ffffff',
        },
    },
    routes: {
        HOME: '/',
        LOGIN: '/login',
        SIGNUP: '/signup',
        PRODUCT_DETAILS: '/product/:url_slug',
        PASSWORD_RESET: '/password-reset',
        PASSWORD_RESET_CONFIRM: '/password-reset-confirm/:token',
        BECOME_A_MERCHANT: '/become-a-merchant',
        VERIFY_EMAIL: '/verify-email/:token',
        DASHBOARD: '/dashboard',
        POST_PRODUCT: '/post-product',
        EDIT_PRODUCT: '/edit-product/:url_slug',
        CART: '/cart',
    },
};

export default Constants;