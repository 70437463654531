import React, { useState } from 'react';
import { Button, Flex } from '..';
import { useMutation, useQueryClient } from 'react-query';
import { useDeviceWidth, useSnackbar } from '../../hooks';
import {
	BrandLogo, 
	HeaderAvatar, 
	HeaderWrapper, 
	LoginButton, 
	MenuButton, 
	Navbar, 
	AddProductButton, 
	SignupButton,
	AddProductIconButton
} from './components/styledComponents';
import Constants from '../../Constants';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import Badge from '@mui/material/Badge';
import { 
	ShoppingBagOutlined, 
	Menu as MenuIcon, 
	ArrowDropDownOutlined, 
	SellOutlined,
	AccountCircleOutlined,
	LogoutRounded,
	AddRounded,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { MerchantModal, useAuth } from '../../../Authentication';
import hexToRgba from 'hex-to-rgba';
import { logout } from '../../../Authentication/services/apiService';
import { CircularProgress } from '@mui/material';
import { useCart } from '../../../Cart';
import { clearLocalStorage } from '../../utils/storage';
interface Props {
	sticky?: boolean;
}

const Header = ({ sticky = true }: Props) => {
	const { isDesktop, isMobile } = useDeviceWidth();
	const { routes, ui: { theme } } = Constants;
	const [showDrawer, setShowDrawer] = useState(false);
	const [menuAnchor, setMenuAnchor] = useState(null);
	const [showMerchantModal, setShowMerchantModal] = useState(false);
	const { showSnackbar } = useSnackbar();

	const { cartCount } = useCart();
	
	const menuOpen = !!menuAnchor;
	
	const { user, clearAuth } = useAuth();

	const navigate = useNavigate();

	const queryClient = useQueryClient();

	const { mutateAsync: _logout, isLoading } = useMutation(logout);

	const handleMenuOpen = (event: any) => {
		setMenuAnchor(event.currentTarget);
	};

	const handleMenuClose = () => {
		setMenuAnchor(null);
	};

	const handleLogout = async () => {
		try {
			await _logout();

			clearAuth();
			queryClient.clear();
			clearLocalStorage();
			handleMenuClose();
			navigate(routes.LOGIN, { replace: true });
		} catch(error: any) {
			showSnackbar({
                message: error.message,
                type: 'error',
            });
		}
	};

	const menuStyle = { 
		marginRight: 1, 
		fontSize: 18, 
		color: hexToRgba(theme.common.BLACK, 0.4) 
	};

	return (
		<>
			<HeaderWrapper px={isDesktop ? 50 : 20} sticky={sticky} py={15} justify="space-between">
				<BrandLogo to={routes.HOME}>Ceillo</BrandLogo>
				<Navbar justify="space-between">
					<Flex mr={isMobile ? 10 : 20}>
						<IconButton onClick={()=>navigate('/cart')}>
							<Badge 
								badgeContent={cartCount ? cartCount : 0} 
								color='error'
							>
								<ShoppingBagOutlined sx={{ fontSize: 30 }} />
							</Badge>
						</IconButton>
						
						{
							user?.merchant && 
							<>
								{
									isDesktop ?
									<Flex ml={15}>
										<AddProductButton 
											endIcon={<AddRounded sx={{ marginBottom: 0.1, marginLeft: -0.4 }} />}
											onClick={() => navigate('/dashboard/post-product')}
											secondary
										>
											Product
										</AddProductButton>
										<Button 
											style={{ 
												height: 35, 
												borderRadius: 4,
												marginLeft: 15
											}}
											onClick={() => navigate('/dashboard')}
										>
											Dashboard
										</Button>
									</Flex> :
									<AddProductIconButton
										onClick={() => navigate('/dashboard/post-product')}
									>
										<AddRounded sx={{ marginBottom: 0.1 }} />
									</AddProductIconButton>

								}
							</> 
						}
					</Flex>

					{
						isDesktop
							? (
								<Flex>
									{
										user ?
											<MenuButton 
												endIcon={
													<ArrowDropDownOutlined 
														sx={{ fontSize: 18, color: 'gray', marginLeft: -0.7 }} 
													/>
												} 
												onClick={handleMenuOpen} 
												secondary
											>
												<HeaderAvatar
														alt={user?.first_name} 
														src={user.photo}
												/>
												{user?.first_name}
											</MenuButton > :	
											<><LoginButton to={routes.LOGIN}>Log in</LoginButton><SignupButton to={routes.SIGNUP}>Sign up</SignupButton></>
									}
								</Flex>
							)
							: (
								<IconButton onClick={() => setShowDrawer(true)}>
									<MenuIcon sx={{ fontSize: 35 }} />
								</IconButton>
							)

					}
				</Navbar>
			</HeaderWrapper>

			<Drawer
				anchor="top"
				open={showDrawer}
				onClose={() => setShowDrawer(false)}
			>
				{
					user ?
					<>
						<MenuItem onClick={() => setShowDrawer(false)}>
							Account
						</MenuItem>
						<Divider />
						{
							!user?.merchant ?
							<MenuItem onClick={() => setShowMerchantModal(true)}>
								Start Selling
							</MenuItem> :
							<>
								<MenuItem 
									onClick={() => {
										navigate('/dashboard');
										setShowDrawer(false);
									}}
								>
									My Products
								</MenuItem>

								<Divider />

								<MenuItem 
									onClick={() => { 
										navigate('/dashboard/post-product');
										setShowDrawer(false);
									}}
								>
									Post Product
								</MenuItem>

								<Divider />

								<MenuItem>Orders</MenuItem>

								<Divider />

								<MenuItem>
									Payment
								</MenuItem>
							</>
						}
						<Divider />
						<MenuItem onClick={handleLogout}>
							<Flex>
								<span style={{ color: theme.status.DANGER }}>Logout </span>
								{
									isLoading &&
									<CircularProgress size={16} sx={{ marginLeft: 1.5, color: theme.status.DANGER }} />
								}
							</Flex>
						</MenuItem>
					</> :
					<>
						<MenuItem onClick={() => navigate(routes.LOGIN)}>
							Log in
						</MenuItem>
						<Divider />
						<MenuItem onClick={() => navigate(routes.SIGNUP)}>
							Sign up
						</MenuItem>
					</>
				}
			</Drawer>

			<Menu
				anchorEl={menuAnchor}
				open={menuOpen}
				onClose={handleMenuClose}
			>
				<MenuItem onClick={handleMenuClose} disableRipple>
					<AccountCircleOutlined sx={menuStyle} />
					Account
				</MenuItem>
				{
					!user?.merchant &&
					<MenuItem onClick={() => setShowMerchantModal(true)} disableRipple>
						<SellOutlined sx={menuStyle} />
						Start Selling
					</MenuItem>

				}
				<Divider sx={{ my: 0.5 }} />
				<MenuItem onClick={handleLogout} disableRipple>
					<LogoutRounded sx={{ ...menuStyle, color: theme.status.DANGER }} />
					<Flex>
						<span style={{ color: theme.status.DANGER }}>Logout </span>
						{
							isLoading &&
							<CircularProgress size={16} sx={{ marginLeft: 1.5, color: theme.status.DANGER }} />
						}
					</Flex>
				</MenuItem>
			</Menu>

			<MerchantModal 
				open={showMerchantModal} 
				onClose={() => setShowMerchantModal(false)} 
			/>
		</>
	);
};

export default Header;