import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery, useQueryClient } from 'react-query';
import { useLocation, useParams } from 'react-router';
import { ContentWrapper, Flex, Header } from '../_shared/components';
import { useDeviceWidth } from '../_shared/hooks';
import { IProduct } from '../_shared/types';
import { formatCurrency } from '../_shared/utils';
import { ProductImages } from './components/ProductImages';
import { 
	ProductDetailsContent, 
	Name, 
	Description, 
	Price, 
	AddToCartButton, 
	BuyButton, 
	ProductFieldLabel, 
	ProductFieldValue,
	ProductFeaturesTable,
} from './components/styledComponents';
import { getProduct } from './services/apiService';
import { useCart } from '../Cart';
export default function ProductDetails() {
    const location = useLocation();

	const initialProduct = location.state as IProduct;

	const { url_slug } = useParams<{ url_slug: string }>();

	const { isMobile } = useDeviceWidth();
	
	const queryClient = useQueryClient();

	const { data: product } = useQuery('product-detail', () => getProduct(url_slug as string), { initialData: initialProduct });

	const { addToCart } = useCart();

	useEffect(() => {
		return () => queryClient.removeQueries('product-detail');
	}, []);

    return (
        <div>
			<Helmet>
				<title>{`${product?.name ? product?.name + ' | ' : ''}Ceillo`}</title>
			</Helmet>
			<Header />
			<ContentWrapper>
				<Flex align="flex-start" flexWrap="wrap" p={5}>
					<ProductImages images={product?.images} />

					<ProductDetailsContent style={{ padding: !isMobile ? '10px 20px' : undefined }}>
						<Flex justify="space-between" mb={15} align="flex-start">
							<Name size={18}>{product?.name}</Name>
							<Price>₵<span style={{fontSize: 18}}>{formatCurrency(product?.price)}</span></Price>
						</Flex>
						<Description size={16} variant='details'>
							{product?.description}
						</Description>

						<Flex direction="column" align="flex-start" mb={30}>
							<p style={{ fontSize: 17, marginBottom: 6 }}>Features</p>
							<ProductFeaturesTable>
								<tbody>
									<tr>
										<ProductFieldLabel>Category:</ProductFieldLabel>
										<ProductFieldValue>{product?.category?.name}</ProductFieldValue>
									</tr>
									<tr>
										<ProductFieldLabel>Brand:</ProductFieldLabel>
										<ProductFieldValue>{product?.brand}</ProductFieldValue>
									</tr>
									<tr>
										<ProductFieldLabel>Stock:</ProductFieldLabel>
										<ProductFieldValue>{product?.stock}</ProductFieldValue>
									</tr>
								</tbody>
							</ProductFeaturesTable>
						</Flex>

						<Flex direction="column" mt={15} mb={20} style={{ marginTop: 'auto'}}>
							<AddToCartButton 
								secondary 
								style={{ 
									width: '100%', 
									height: 45, 
									marginBottom: 15,
									fontSize: 15
								}}
								onClick={() => addToCart(product)}
							>
								Add to Cart
							</AddToCartButton>
							<BuyButton 
								style={{ 
									width: '100%',
									height: 45, 
									fontSize: 15
								}}
							>
								Buy
							</BuyButton>
						</Flex>
					</ProductDetailsContent>
				</Flex>
			</ContentWrapper>
		</div>
    );
}
