import React from 'react';
import { useInfiniteQuery } from 'react-query';
import { TriggerOnView } from '../_shared/components';
import { useDeviceWidth } from '../_shared/hooks';
import { IProduct } from '../_shared/types';
import Product from './components/Product';
import ProductSkeleton from './components/ProductSkeleton';
import { ProductsWrapper } from './components/styledComponents';
import { getProducts } from './services/apiService';

export default function Products() {
	const { isMobile } = useDeviceWidth();

	const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } = useInfiniteQuery(
			'products', 
			({ pageParam = undefined }) => getProducts(pageParam),
			{
				getNextPageParam: (lastPage) => lastPage.next
			}
		);

	return (
		<div>
			<ProductsWrapper style={{ display: !isMobile ? 'grid' : 'flex', width: !isMobile ? '84%' : undefined }}>
				{
					isLoading ?
					Array.from({ length: 6 }, (_, i) => <ProductSkeleton key={i} />) :
					data?.pages?.map(page => (
						page?.results?.map(
							(product: IProduct, i: string) => (
								<Product 
									key={i} 
									product={product} 
								/>
						))
					))
				}
				{
					isFetchingNextPage &&
					Array.from({ length: isMobile ? 1 : 3 }, (_, i) => <ProductSkeleton key={i} />)
				}
				{
					hasNextPage &&
					<TriggerOnView callback={fetchNextPage} />
				}
			</ProductsWrapper>
		</div>
	);
}
