import * as yup from 'yup';

export const postProductSchema = yup.object({
    name: yup.string().required('Required'),
    brand: yup.string().required('Required'),
    category: yup.string().required('Required'),
    description: yup.string().required('Required'),
    price: yup.string().required('Required'),
    stock: yup.string().required('Required'),
    images: yup.mixed()
    .test("required", "A minimum of one image is required", (value: File[]) => {
        return Array.from(value).length > 0;
    })
    .test("fileSize", "The image(s) is/are too large", (value: File[]) => {
        return !Array.from(value).some(image => image?.size > 2000000);
    }),
});