import React, { useState, createContext } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { User } from '../../_shared/types';
import { clearLocalStorage, getFromLocalStorage, saveToLocalStorage } from '../../_shared/utils/storage';
import { getUserDetails } from '../services/apiService';

interface IAuthContext {
    user: User | null, 
    setUser: React.Dispatch<React.SetStateAction<User | null>>,
    clearAuth: () => void;
    refetch: () => void;
}

export const AuthContext = createContext<IAuthContext>({
    user: null, 
    setUser: () => null, 
    clearAuth: () => null,
    refetch: () => null,
});


export default function AuthProvider({ children}: { children: any }) {
    const initialUser = getFromLocalStorage('user');

    if (initialUser) {
        window.accessToken = getFromLocalStorage('accessToken');
        window.refreshToken = getFromLocalStorage('refreshToken');
    }

    const [user, setUser] = useState<User | null>(initialUser);

    const queryClient = useQueryClient();
    
    const { refetch } = useQuery('user', getUserDetails, {
        onSuccess: ({ data }) => {
            setUser(data);
            saveToLocalStorage('user', data);
        },
        onError: error => {
            console.log(error);
        },
        enabled: !!window.accessToken,
        refetchOnWindowFocus: false,
    });

    const clearAuth = () => {
        clearLocalStorage();
        queryClient.clear();
        setUser(null);
    };
    
    return (
        <AuthContext.Provider value={{ user, setUser, clearAuth, refetch }}>
            {children}
        </AuthContext.Provider>
    );
}
