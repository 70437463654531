import React from 'react';
import styled from 'styled-components';
import { IFlex } from '../types';
import Constants from '../../_shared/Constants';
import { Skeleton as CustomSkeleton } from '@mui/material';
import hexToRgba from 'hex-to-rgba';


export const ContentWrapper = styled.div`
    padding: 0 2%;
    @media (min-width: ${Constants.ui.device.TABLET_MIN_WIDTH}px) {
        padding: 0 5%;
    };
    @media (min-width: ${Constants.ui.device.DESKTOP_MIN_WIDTH}px) {
        padding: 0 15%;
    };
`;

export const Flex = styled.div<IFlex>(
	({
		direction,
		align,
		self,
		flex,
		justify,
		flexWrap,
		mb,
		ml,
		mr,
		mt,
		mx,
		my,
		m,
		pb,
		pl,
		pr,
		pt,
		px,
		py,
		p
	}) => ({
		display: 'flex',
		flexDirection: direction || 'row',
		flex,
		alignItems: align || 'center',
		justifyContent: justify,
		alignSelf: self,
		flexWrap,
		margin: m ? `${m}px` : `${my || 0}px ${mx || 0}px`,
		marginTop: `${mt}px`,
		marginBottom: `${mb}px`,
		marginLeft: `${ml}px`,
		marginRight: `${mr}px`,
		padding: p ? `${p}px` : `${py || 0}px ${px || 0}px`,
		paddingTop: `${pt}px`,
		paddingBottop: `${pb}px`,
		paddingLeft: `${pl}px`,
		paddingRight: `${pr}px`
	})
);

export const Skeleton = styled(props => <CustomSkeleton animation="wave" {...props} />)(({ theme }) => ({
	backgroundColor: hexToRgba(theme.common.BLACK, 0.07)
}));
