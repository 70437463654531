import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex } from '../../_shared/components';
import { useDeviceWidth } from '../../_shared/hooks';
import { IProduct } from '../../_shared/types';
import Clamp from 'react-multiline-clamp';
import { formatCurrency } from '../../_shared/utils';
import {
    AddToCartButton,
    BuyButton,
    Content,
    Description,
    Image,
    Name,
    Price,
    Wrapper,
} from './styledComponents';
import { useCart } from '../../Cart';
interface Props {
    product: IProduct;
}

export default function Product({ product }: Props) {
    const { isMobile } = useDeviceWidth();
    const navigate = useNavigate();
    const { addToCart } = useCart();

    return (
        <Wrapper style={{ width: isMobile ? '100%' : undefined }}>
            <Image
                src={product?.images[0]?.image_url}
                alt={product.name}
                style={{ height: isMobile ? 250 : 170 }}
                onClick={() =>
                    navigate(`/product/${product.url_slug}`, { state: product })
                }
            />

            <Content
                onClick={() =>
                    navigate(`/product/${product.url_slug}`, { state: product })
                }
            >
                <Flex justify="space-between" align="flex-start" py={5}>
                    <Clamp lines={1}>
                        <Name>{product.name}</Name>
                    </Clamp>
                    <Price>
                        ₵
                        <span style={{ fontSize: 18 }}>
                            {formatCurrency(product.price)}
                        </span>
                    </Price>
                </Flex>

                <Clamp lines={2}>
                    <Description>{product.description}</Description>
                </Clamp>
            </Content>
            <Flex justify="space-between" mt={15} px={5}>
                <AddToCartButton
                    secondary
                    onClick={() => addToCart(product)}
                >
                    Add to Cart
                </AddToCartButton>
                <BuyButton>Buy</BuyButton>
            </Flex>
        </Wrapper>
    );
}
