import React, { useEffect, useState } from 'react';
import { Flex } from '../../_shared/components';
import { useDeviceWidth } from '../../_shared/hooks';
import { Image } from '../../_shared/types';
import { ProductDetailsImage, ProductDetailsSubImage } from './styledComponents';

interface Props {
    images: Image[];
}

export const ProductImages = ({ images }: Props) => {
    const [currentImage, setCurrentImage] = useState(images?.[0]);

    const { isMobile } = useDeviceWidth();

    useEffect(() => {
        setCurrentImage(images?.[0]);
    }, [images]);

    return (
        <div style={{ width: isMobile ? '100%' : 400 }}>
            <ProductDetailsImage 
                src={currentImage?.image_url}
                style={{ width: isMobile ? '100%' : 400, height: isMobile ? 370 : 400 }} 
			/>
            <Flex mt={15} mb={10}>
                {
                    images?.length > 1 &&
                        images?.map(image => 
                            <ProductDetailsSubImage 
                                key={image?.id}
                                src={image?.image_url}
                                selected={currentImage?.id === image?.id}
                                onClick={() => setCurrentImage(image)}
                            />
                        )
                }
            </Flex>
        </div>
    );
};
