import React from 'react';
import CustomSnackbar, { SnackbarProps as _SnackbarProps } from '@mui/material/Snackbar';
import Alert, { AlertProps } from '@mui/material/Alert';
import Slide, { SlideProps } from '@mui/material/Slide';

export interface SnackbarProps extends _SnackbarProps {
    duration?: 'short' | 'normal' | 'long' | 'very-long';
    type?: AlertProps['severity'];
}

function SlideTransition(props: SlideProps) {
    return <Slide {...props} />;
}

export default function Snackbar(props: SnackbarProps) {
    const { message, duration, type, ...rest } = props;
    
    return (
        <div>
            <CustomSnackbar
                autoHideDuration={
                    duration === 'short' ? 3000 : 
                    duration === 'long' ? 6000 : 
                    duration === 'very-long' ? 3600000 : 4500
                }
                TransitionComponent={SlideTransition}
                {...rest}
            >
                <Alert
                    onClose={rest.onClose as AlertProps['onClose']}
                    severity={type || 'info'} 
                    sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
            </CustomSnackbar>
        </div>
    );
}
