import { useContext } from 'react';
import { IProduct } from '../../_shared/types';
import { CartContext } from '../components/CartProvider';
import { ICartItem } from '../../_shared/types/types';

export const useCart = () => {
    const cart = useContext(CartContext);

    const addToCart = (product: IProduct) => {
        const cartItem = cart.cartItems.find(item => item.product.url_slug === product.url_slug);

        if (cartItem) {
            cart.setCartItems(prevItems => {
                return prevItems.map(item =>
                    item.product.url_slug === product.url_slug
                        ? {
                              ...item,
                              quantity: item.quantity ? item.quantity + 1 : 1,
                          }
                        : { ...item },
                );
            });
        } else {
            cart.setCartItems(prevItems => [
                ...prevItems,
                { product:product, quantity: 1 },
            ]);
        }
    };

    const removeFromCart = (product:IProduct) => {
            cart.setCartItems((prevItems)=>{
            return prevItems.reduce((acc:ICartItem[],item)=>{
                if (item.product.url_slug === product.url_slug){
                    if (item.quantity > 1){
                        return [...acc, {...item, quantity: item.quantity - 1}];
                    }else
                    return [...acc];
                }
                else{
                    return [...acc, item];
                }
            }, []);
        });
    };

    return { ...cart, addToCart, removeFromCart };
};
