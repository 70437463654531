import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import { Button, Flex } from '../../_shared/components';

export const Wrapper = styled.div(({ theme }) => ({
    background: hexToRgba(theme.SECONDARY, 0.4),
    height: '100vh',
    overflowY: 'auto',
}));

export const CartWrapper = styled.div({
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridTemplateRows: '126px 1fr',
    gap: '20px',
    width: '65%',
    margin: '0 auto',
    '@media (max-width: 750px)': {
        display: 'flex',
        flexDirection: 'column',
        position: 'initial',
        '.cartitem': {
        order: -1,
        position: 'inherit',
            top: -1,
        },
    },
});

export const CartSummary = styled.div(({ theme }) => ({
    background: theme.common.WHITE,
    gridRow: '1 / 2',
    gridColumn: '9 / -1',
    position: 'sticky',
    top: 30,
    padding: 13,
    border: `1px solid ${hexToRgba(theme.PRIMARY, 0.15)}`,
    borderRadius: 6,
    marginBottom: 10,
    'h3': {
        borderRadius: 5,
    },
    '.total': {
        fontSize: 18,
    },
    ".label": {
        fontSize: 20,
    },
    '@media (max-width: 750px)': {
        order: -1,
        position: 'inherit',
        top: -1,
    },
}));

export const CartItemsWrapper = styled.div({
    borderRadius: '10px',
    gridColumn: 'span 8',
});

export const ItemWrapper = styled(Flex)(({ theme }) => ({
    background: theme.common.WHITE,
    borderRadius: 8,
    marginBottom: 18,
    fontSize: 15,
    padding: 7,
    boxShadow: `3px 3px 1px ${hexToRgba(theme.common.BLACK, 0.02)}`,
    img: {
        height: 85,
        borderRadius: 7,
        objectFit: 'cover',
        aspectRatio: '1',
    },
    '.title': {
        fontSize: 16.5,
        margin: 0
    },
    '.price': {
        fontSize: 16.5,
        color: hexToRgba(theme.common.BLACK, 0.6),
    },
    '.qty': {
        padding: '0 10px',
        backgrondColor: theme.SECONDARY,
        fontSize: 16,
        minWidth: 30,
        textAlign: 'center',
    },
    '> div': {
        marginLeft: 15
    }
}));

export const CheckoutButton = styled(Button)({
    marginTop: 15,
    width: '100%',
    fontSize: 15
});
