import React from 'react';
import { Helmet } from 'react-helmet';
import { useInfiniteQuery } from 'react-query';
import { EmptyList, TriggerOnView } from '../_shared/components';
import { IProduct } from '../_shared/types';
import MyProduct from './components/MyProduct';
import MyProductSkeleton from './components/MyProductSkeleton';
import { getMyProducts } from './services/apiService';

export const MyProducts = () => {
    const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } = useInfiniteQuery(
        'my-products', 
        ({ pageParam = undefined }) => getMyProducts(pageParam),
        {
            getNextPageParam: (lastPage) => lastPage.next
        }
    );
    
    return (
        <div style={{ width: '100%' }}>
            <Helmet>
                <title>My Products | Ceillo</title>
            </Helmet>
            {
					isLoading ?
					Array.from({ length: 6 }, (_, i) => <MyProductSkeleton key={i} />) :
					data?.pages?.map(page => (
						page?.results?.map(
							(product: IProduct, i: string) => (
								<MyProduct key={i} product={product} />
						))
					))
			}
            {
                !data?.pages && !isLoading &&
                <EmptyList
                caption="Products you have posted will appear here"
            />
            }
            {
                isFetchingNextPage &&
                Array.from({ length: 2 }, (_, i) => <MyProductSkeleton key={i} />)
            }
            {
                hasNextPage &&
                <TriggerOnView callback={fetchNextPage} />
            }
        </div>
    );
};

export default MyProducts;
