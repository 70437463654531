import { useFormik } from 'formik';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { Flex } from '../_shared/components';
import FormField, { CheckboxField, SelectField } from '../_shared/components/FormField';
import Constants from '../_shared/Constants';
import { useDeviceWidth, useSnackbar } from '../_shared/hooks';
import { getErrorMessage } from '../_shared/utils';
import { 
    BottomText, 
    BrandLogo, 
    FormCaption, 
    FormSubmitButton, 
    FormWrapper, 
    OAuthButton, 
    OAuthButtonImage, 
    SeparatorLine, 
    SeparatorText, 
    Wrapper 
} from './components/styledComponents';
import { signUp } from './services/apiService';
import { signupSchema } from './services/validation';

export default function Signup() {
    const { isDesktop } = useDeviceWidth();
    const { routes } = Constants;


    const { showSnackbar } = useSnackbar();

    const { mutateAsync, isLoading } = useMutation(signUp);

    const form = useFormik({
        validationSchema: signupSchema,
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            university: 'KNUST',
            password: '',
            password2: '',
            agreed_to_terms: false,
        },
        onSubmit: async values => {
            try {
                const { message } = await mutateAsync(values);

                showSnackbar({
                    message: message,
                    type: 'success',
                });

                form.resetForm();
            } catch(error: any) {
                showSnackbar({
                    message: getErrorMessage(error),
                    type: 'error',
                });
            }  
        }
    });

    return (
        <Wrapper>
            <Helmet>
                <title>Sign up | Ceillo</title>
            </Helmet>

            <BrandLogo to="/">Ceillo</BrandLogo>

            <FormWrapper onSubmit={form.handleSubmit} style={{ width: isDesktop ? '30%' : '85%'}}>
                <FormCaption>Lorem ipsum, dolor sit amet consectetur adipisicing</FormCaption>
                
                <Flex justify="space-between">
                    <FormField 
                        label="First Name" 
                        placeholder="John" 
                        type="text" 
                        style={{ width: '48%' }} 
                        value={form.values.first_name}
                        onChange={form.handleChange('first_name')}
                        error={
                            form.touched.first_name && 
                            form.errors.first_name
                        }
                    />
                    <FormField 
                        label="Last Name" 
                        placeholder="Doe" 
                        type="text" 
                        style={{ width: '48%' }} 
                        value={form.values.last_name}
                        onChange={form.handleChange('last_name')}
                        error={
                            form.touched.last_name && 
                            form.errors.last_name
                        }
                    />
                </Flex>

                <Flex justify="space-between">
                    <FormField 
                        label="Phone Number" 
                        placeholder="024********" 
                        type="tel" 
                        style={{ width: '48%' }}
                        value={form.values.phone_number}
                        onChange={form.handleChange('phone_number')}
                        error={
                            form.touched.phone_number && 
                            form.errors.phone_number
                        } 
                    />
                    <SelectField
                        label="University"
                        value={form.values.university}
                        onChangeItem={form.handleChange('university')}
                        items={[
                            {
                                label: 'KNUST',
                                value: 'KNUST'
                            },
                            {
                                label: 'University of Ghana',
                                value: 'UG'
                            }
                        ]}
                        style={{ width: '48%' }}
                    />
                </Flex>

                <FormField 
                    label="Email" 
                    type="email" 
                    placeholder="example@somewhere.com" 
                    value={form.values.email}
                    onChange={form.handleChange('email')}
                    error={
                        form.touched.email && 
                        form.errors.email
                    }
                />

                <FormField
                    label="Password" 
                    placeholder="at least 8 characters" 
                    type="password"
                    value={form.values.password}
                    onChange={form.handleChange('password')}
                    error={
                        form.touched.password && 
                        form.errors.password
                    }
                />

                <FormField
                    label="Confirm Password" 
                    placeholder="at least 8 characters" 
                    type="password"
                    value={form.values.password2}
                    onChange={form.handleChange('password2')}
                    error={
                        form.touched.password2 && 
                        form.errors.password2
                    }
                />

                <CheckboxField
                    label={<span style={{ fontSize: 15 }}>I agree to the <Link to="/">Terms and Conditions</Link></span>}
                    type="checkbox"
                    checked={form.values.agreed_to_terms}
                    onChange={form.handleChange('agreed_to_terms')}
                    error={
                        form.touched.agreed_to_terms && 
                        form.errors.agreed_to_terms
                    }
                />

                <FormSubmitButton 
                    loading={isLoading} 
                    type="submit"
                >
                    Sign up
                </FormSubmitButton>

                <Flex my={5}>
                    <SeparatorLine />
                    <SeparatorText>OR</SeparatorText>
                    <SeparatorLine />
                </Flex>

                <OAuthButton 
                    secondary 
                    startIcon={
                        <OAuthButtonImage 
                            src="https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-suite-everything-you-need-know-about-google-newest-0.png" 
                        />
                    }>
                    Sign up with Google
                </OAuthButton>
                <OAuthButton 
                    secondary 
                    startIcon={
                        <OAuthButtonImage 
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Facebook_Logo_%282019%29.png/1024px-Facebook_Logo_%282019%29.png" 
                        />
                    }>
                    Sign up with Facebook
                </OAuthButton>

                <Flex justify="center">
                    <BottomText>
                        Already have an account? <Link to={routes.LOGIN}>Log in</Link>
                    </BottomText>
                </Flex>
            </FormWrapper>
        </Wrapper>
    );
}
