import hexToRgba from 'hex-to-rgba';
import styled from 'styled-components';
import { Button } from '../../_shared/components';

export const Wrapper = styled.div(({ theme }) => ({
	cursor: 'pointer',
	':hover': {
		backgroundColor: hexToRgba(theme.SECONDARY, 0.5),
	},
	padding: 10,
	borderRadius: 10,
	margin: 10
}));

export const ProductsWrapper = styled.div({
	flexWrap: 'wrap',
	gridTemplateColumns: 'repeat(3, 250px)',
	margin: '0 auto'
});

export const Image = styled.img({
	width: '100%',
	borderRadius: 6,
	objectFit: 'cover',
});

export const Name = styled.span<{ size?: number }>(({ theme, size }) => ({
	color: theme.PRIMARY,
	marginRight: 15,
	fontSize: size || undefined,
}));

export const Price = styled.span<{ size?: number }>(({ theme, size }) => ({
	color: hexToRgba(theme.PRIMARY, 0.9),
	fontWeight: 600,
	fontSize: size || undefined,
}));

export const Description = styled.p<{ size?: number; variant?: 'details' }>(({ theme, size, variant }) => ({
	color: hexToRgba(theme.PRIMARY, 0.6),
	fontSize: size || 14,
	lineHeight: variant ? 1.6 : 1.4,
	margin: '0 0',
}));

export const Content = styled.div({
	padding: '2px 5px',
});

export const AddToCartButton = styled(Button)(({ theme }) => ({
	backgroundColor: hexToRgba(theme.SECONDARY, 0.7),
	fontSize: 14,
	// padding: '7px 12px',
	height: 35,
}));

export const BuyButton = styled(Button)({
	fontSize: 14,
	// padding: '8px 20px',
	borderRadius: 5,
	height: 35,
});

export const ProductDetailsImage = styled.img({
	borderRadius: 10,
	objectFit: 'cover',
	height: 400,
});

export const ProductDetailsSubImage = styled.img<{ selected: boolean }>(
	({ theme, selected }) => ({
		borderRadius: 5,
		objectFit: 'cover',
		height: 60,
		width: 60,
		boxShadow: selected ? `0 0 0 4px ${hexToRgba(theme.TERTIARY, 0.4)}` : undefined,
		marginRight: 15,
		cursor: 'pointer',
	})
);

export const ProductDetailsContent = styled.div({
	flex: 1,
	padding: 5,
	minHeight: 400,
	display: 'flex',
	flexDirection: 'column'
});

export const ProductFieldLabel = styled.td(({ theme }) => ({
	color: hexToRgba(theme.common.BLACK, 0.5),
}));

export const ProductFeaturesTable = styled.table({
	'td': {
		padding: 3
	},
	'td:last-child': {
		paddingLeft: 15
	},
	'td:first-child': {
		paddingLeft: 0
	}
});

export const ProductFieldValue = styled.td(({ theme }) => ({
	color: theme.common.BLACK,
}));
