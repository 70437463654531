import { MenuItem, SelectChangeEvent, SelectProps } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { 
    CustomSelect,
    FormCaptionText, 
    FormErrorText, 
    FormFieldWrapper, 
    FormInputWrapper, 
    FormLabel,  
} from './styledComponents';

interface SelectItem {
    label: string;
    value: string;
}

interface Props extends Omit<SelectProps, 'onChange'> {
    label?: string;
    error?: any;
    caption?: string;
    items: SelectItem[];
    // eslint-disable-next-line no-unused-vars
    onChangeItem: (event: ChangeEvent<any>) => void;
}

// eslint-disable-next-line no-unused-vars
type SelectChange = (event: SelectChangeEvent<any>) => void;


export default function SelectField(props: Props) {
    const { 
        label, 
        items, 
        onChangeItem, 
        error, 
        style, 
        caption, 
        ...rest 
    } = props;
    
    return (
        <FormFieldWrapper style={style}>
            {
                label && 
                <FormLabel>{label}</FormLabel>
            }
            <FormInputWrapper error={!!error}>
                <CustomSelect
                    onChange={onChangeItem as unknown as SelectChange}
                    {...rest}
                >
                    {
                        items.map(
                            item =>  
                                <MenuItem 
                                    key={item.value} 
                                    value={item.value}
                                >
                                    {item.label}
                                </MenuItem>
                        )
                    }
                </CustomSelect>
            </FormInputWrapper>
            {
                error &&
                <FormErrorText>{error}</FormErrorText>
            }
            {
                caption &&
                <FormCaptionText>{caption}</FormCaptionText>
            }
        </FormFieldWrapper>
    );
}
