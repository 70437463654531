import React from 'react';
import { useFormik } from 'formik';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { Flex } from '../_shared/components';
import FormField from '../_shared/components/FormField';
import Constants from '../_shared/Constants';
import { useDeviceWidth, useSnackbar } from '../_shared/hooks';
import { 
    BottomText, 
    BrandLogo, 
    ForgotPassword, 
    FormCaption, 
    FormSubmitButton, 
    FormWrapper, 
    OAuthButton, 
    OAuthButtonImage, 
    SeparatorLine, 
    SeparatorText, 
    Wrapper 
} from './components/styledComponents';
import { useMutation } from 'react-query';
import { login } from './services/apiService';
import { saveToLocalStorage } from '../_shared/utils/storage';
import { loginSchema } from './services/validation';
import jwtDecode from 'jwt-decode';
import { useAuth } from './hooks/useAuth';
import { User } from '../_shared/types';
import { getErrorMessage } from '../_shared/utils';

export default function Login() {
    const { isDesktop } = useDeviceWidth();
    const { routes } = Constants;
    const { showSnackbar } = useSnackbar();

    const navigate = useNavigate();

    const { setUser, refetch: refetchUser } = useAuth();

    const { mutateAsync, isLoading } = useMutation(login);
    
    const form = useFormik({
        validationSchema: loginSchema,
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit: async values => {
            try {
                const data = await mutateAsync(values);

                const decodedToken = jwtDecode(data.access) as any;
                const user = { 
                    id: decodedToken.user_id,
                    first_name: decodedToken.first_name,
                    photo: decodedToken.photo
                };

                setUser(user as User);

                saveToLocalStorage('user', user);
                saveToLocalStorage('accessToken', data.access);
                saveToLocalStorage('refreshToken', data.refresh);

                // using invalidateQueries doesn't work on queries with enabled set
                // probably an internal react query bug
                refetchUser();

                if (decodedToken.merchant_id) {
                    navigate('/dashboard', { replace: true });
                } else {
                    navigate('/', { replace: true });
                }

            } catch(error: any) {
                showSnackbar({
                    message: getErrorMessage(error),
                    type: 'error',
                });
            }  
        },
    });
    
    return (
        <Wrapper>
            <Helmet>
                <title>Log in | Ceillo</title>
            </Helmet>

            <BrandLogo to="/">Ceillo</BrandLogo>

            <FormWrapper onSubmit={form.handleSubmit} style={{ width: isDesktop ? '30%' : '85%'}}>
                <FormCaption>Lorem ipsum, dolor sit amet consectetur adipisicing</FormCaption>

                <FormField 
                    value={form.values.email}
                    onChange={form.handleChange('email')}
                    label="Email" 
                    type="email" 
                    placeholder="example@somewhere.com" 
                    error={form.touched.email && form.errors.email}
                />

                <FormField 
                    value={form.values.password}
                    onChange={form.handleChange('password')}
                    label="Password" 
                    placeholder="at least 8 characters" 
                    type="password" 
                    error={form.touched.password && form.errors.password}
                />

                <ForgotPassword 
                    to={routes.PASSWORD_RESET}
                    state={{ email: form.values.email }}
                >
                    Forgot password?
                </ForgotPassword>

                <FormSubmitButton 
                    type="submit"
                    loading={isLoading} 
                >
                    Log in
                </FormSubmitButton>

                <Flex my={5}>
                    <SeparatorLine />
                    <SeparatorText>OR</SeparatorText>
                    <SeparatorLine />
                </Flex>

                <OAuthButton 
                    secondary 
                    startIcon={
                        <OAuthButtonImage 
                            src="https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-suite-everything-you-need-know-about-google-newest-0.png" 
                        />
                    }>
                    Log in with Google
                </OAuthButton>
                <OAuthButton 
                    secondary 
                    startIcon={
                        <OAuthButtonImage 
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Facebook_Logo_%282019%29.png/1024px-Facebook_Logo_%282019%29.png" 
                        />
                    }>
                    Log in with Facebook
                </OAuthButton>

                <Flex justify="center">
                    <BottomText>
                        Do not have an account? <Link to={routes.SIGNUP}>Sign up</Link>
                    </BottomText>
                </Flex>
            </FormWrapper>
        </Wrapper>
    );
}
