import { Divider, MenuItem as CustomMenuItem } from '@mui/material';
import hexToRgba from 'hex-to-rgba';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { MenuWrapper } from './styledComponent';

export const Menu = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    return (
        <MenuWrapper>
            <MenuItem
                current={pathname === '/dashboard'}
                onClick={() => navigate('')}
            >
                <p>My Products</p>
            </MenuItem>
            <Divider light style={{ margin: 0 }} />
            <MenuItem 
                current={pathname === '/dashboard/post-product'}
                onClick={() => navigate('post-product')}
            >
                <p>Post Product</p>
            </MenuItem>
            <Divider light style={{ margin: 0 }} />
            <MenuItem disableRipple><p>Orders</p></MenuItem>
            <Divider light style={{ margin: 0 }} />
            <MenuItem >
                <p>Payment</p>
            </MenuItem>
        </MenuWrapper>
    );
};

export default Menu;

const MenuItem = styled(props => <CustomMenuItem disableRipple {...props} />)<{ current?: boolean }>(({ theme, current }) => ({
    ':hover': {
        backgroundColor: hexToRgba(theme.common.BLACK, 0.04)
    },
    backgroundColor: current ? hexToRgba(theme.common.BLACK, 0.04) : theme.common.WHITE,
    ':first-child': {
        borderTopRightRadius: 6,
        borderTopLeftRadius: 6
    },
    ':last-child': {
        borderBottomRightRadius: 6,
        borderBottomLeftRadius: 6
    }
}));
