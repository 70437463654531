import axios from "axios";
import { LoginData, MerchantData, PasswordResetData, SignupData } from "../../_shared/types";
import { toFormData } from "../utils";
import { getSecureAxios } from "./authService";

export const login = async (args: LoginData) => {
    const { data } = await axios.post('/auth/login/', args);
    return data;
};

export const signUp = async (args: SignupData) => {
    const { data } = await axios.post('/auth/sign-up/', args);
    return data;
};

export const getUserDetails = async () => {
    const secureAxios = getSecureAxios();

    const { data } = await secureAxios.get('/auth/account-details/');
    return data;
};

export const getRefreshToken = async (refreshToken: string) => {
    const secureAxios = getSecureAxios();

    const { data } = await secureAxios.post('/auth/token-refresh/', { refresh: refreshToken });
    return data;
};

export const resetPassword = async (args: { email: string }) => {
    const { data } = await axios.post('/auth/password-reset/', args);
    return data;
};

export const resetPasswordConfirm = async (args: PasswordResetData) => {
    const { data } = await axios.post('/auth/password-reset-confirm/', args);
    return data;
};

export const verifyEmail = async (token: string)  => {
    const { data } = await axios.post('/auth/verify-email/', { token });
    return data;
};

export const resendEmail = async (token: string)  => {
    const { data } = await axios.post('/auth/resend-email/', { token });
    return data;
};

export const becomeAMerchant = async (args: MerchantData) => {
    const secureAxios = getSecureAxios();

    const { data } = await secureAxios.post(`/auth/create-merchant/`, toFormData(args));

    return data;
};

export const logout = async () => {
    const secureAxios = getSecureAxios();

    const { data } = await secureAxios.post(`/auth/logout/`, { refresh: window.refreshToken });
    return data;
};
