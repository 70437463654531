import React, { useState, InputHTMLAttributes } from 'react';
import { 
    FormCaptionText, 
    FormErrorText, 
    FormFieldWrapper, 
    FormInput, 
    FormInputWrapper, 
    FormLabel, 
    ShowPasswordButton 
} from './components/styledComponents';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    error?: any;
    caption?: string;
}

export default function FormField({ value, label, error, type, style, caption, ...rest }: Props) {
    const [_type, setType] = useState(type);

    const toggleType = () => {
        if (_type === 'password') {
            setType('text');
        } else {
            setType('password');
        }
    };

    return (
        <FormFieldWrapper style={style}>
            {
                label && 
                <FormLabel>{label}</FormLabel>
            }
            <FormInputWrapper error={!!error}>
                <FormInput value={value} type={_type} {...rest} />
                {
                    (type === 'password' && value) &&
                    <ShowPasswordButton onClick={toggleType}>{_type === 'password' ? 'show' : 'hide'}</ShowPasswordButton>
                }
            </FormInputWrapper>
            {
                error &&
                <FormErrorText>{error}</FormErrorText>
            }
            {
                caption &&
                <FormCaptionText>{caption}</FormCaptionText>
            }
        </FormFieldWrapper>
    );
}
