import React from 'react';
import { Helmet } from 'react-helmet';
import Products from '../Products';
import { ContentWrapper, Header } from '../_shared/components';

export default function Home() {
	return (
		<div>
			<Helmet>
				<title>Home</title>
			</Helmet>
			<Header />
			<ContentWrapper>
				<Products />
			</ContentWrapper>
		</div>
	);
}
