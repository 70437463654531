import React from 'react';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import Navigator from "./Navigator";
import Constants from './_shared/Constants';
import { Theme } from './_shared/types';
import { SnackbarProvider } from './_shared/components/Snackbar';
import { QueryClient, QueryClientProvider } from 'react-query';
import AuthProvider from './Authentication/components/AuthProvider';
import axios from 'axios';

import CartProvider from './Cart/components/CartProvider';

const { theme } = Constants.ui;

axios.defaults.baseURL = Constants.app.BASE_URL;

const CustomTheme = createTheme({
  palette: {
    primary: { main: theme.PRIMARY},
    secondary: { main: theme.SECONDARY },
    info: { main: theme.PRIMARY },
    common: {
      black: theme.common.BLACK,
      white: theme.common.WHITE,
    },
  },
  typography: {
    fontFamily: 'Euclid',
  },
});

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <MuiThemeProvider theme={CustomTheme}>
          <ThemeProvider theme={Constants.ui.theme as Theme}>
            <SnackbarProvider>
              <CartProvider>
                <Navigator /> 
              </CartProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
