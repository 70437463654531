import React, { ChangeEvent, InputHTMLAttributes } from 'react';
import { SelectField } from '..';
import { Flex } from '../..';
import { MerchantData } from '../../../types';
import { 
    FormErrorText, 
    FormFieldWrapper, 
    FormInput, 
    FormLabel,
    IdCard,
    IdCardInputLabel,
} from './styledComponents';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label?: string | React.ReactNode;
    error?: any;
    idCard: File;
    idCardType: MerchantData['id_card_type'];
    // eslint-disable-next-line no-unused-vars
    onChangeCard: (image: File) => void;
    // eslint-disable-next-line no-unused-vars
    onChangeType: (event: ChangeEvent<MerchantData['id_card_type']>) => void;
}

const idCardTypes = [
    {
        label: 'Student',
        value: 'Student'
    },
    {
        label: 'National',
        value: 'National'
    },
    {
        label: 'Voter',
        value: 'Voter'
    },
    {
        label: 'Driver',
        value: 'Driver'
    }
];

export default function IdCardField(props: Props) {
    const { 
        idCard, 
        idCardType, 
        onChangeCard, 
        onChangeType,
        label, 
        error, 
        style, 
        ...rest
    } = props; 

    const handleCardChange = (event: ChangeEvent<any>) => {
        onChangeCard(event.target.files[0]);
    };
   
    return (
        <FormFieldWrapper style={style}>
            {
                label && 
                <FormLabel>{label}</FormLabel>
            }
            <Flex align="center">
            <SelectField
                value={idCardType}
                onChangeItem={onChangeType}
                items={idCardTypes}
                style={{ marginTop: 0 }}
            />

            <IdCard src={idCard?.name ? URL.createObjectURL(idCard) : 'https://www.pulsecarshalton.co.uk/wp-content/uploads/2016/08/jk-placeholder-image.jpg'} />
            
            <IdCardInputLabel htmlFor="image">Choose</IdCardInputLabel>
            <FormInput 
                type="file"
                onChange={handleCardChange}
                id="image"
                hidden
                {...rest} 
            />
            </Flex>
            {
                error && 
                <FormErrorText>{error}</FormErrorText>
            }
        </FormFieldWrapper>
    );
}
