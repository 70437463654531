import axios from 'axios';
import { Image } from '../../_shared/types';

export const getFilesFromRemoteImages = async (images: Image[]) => {
    return await Promise.all(
        images.map(async image => {
            const res = await axios.get(image.image_url, { responseType: 'blob' });
            const blob = await res.data;
            const newImage = new File([blob], image.image_url, { type: blob.type });

            return newImage;
        })
    );
};

function areObjectsEquivalent(a: any, b: any) {
    // if the objects are files
    // stringifying the file objects return undefined
    if (a?.size && a?.type || b?.size && b?.type) {
        a = {
            name: a?.name,
            size: a?.size,
            type: a?.type
        };

        b = {
            name: b?.name,
            size: b?.size,
            type: b?.type
        };
    }

    return JSON.stringify(a) === JSON.stringify(b);
}

const areArraysOfObjectsEquivalent = (x: any, y: any) => {
    let hasChanged = false;

    // iterate over the longest array
    // to ensure that arrays of unequal lengths are compared properly
    if (x.length > y.length) {
        for(let i = 0; i < x.length; i++) {
            if (!areObjectsEquivalent(x[i], y[i])) {
                hasChanged = true;
            }
        }
    } else {
        for(let i = 0; i < y.length; i++) {
            if (!areObjectsEquivalent(x[i], y[i])) {
                hasChanged = true;
            }
        }
    }

    return hasChanged;
};

export const getChangedValues = (initialValues: any, values: any) => {
    if (initialValues) {
        return Object
          .entries(values)
          .reduce((accumulator: any, [key, value]) => {
                let hasChanged = false;

                if (Array.isArray(value)) {
                   hasChanged = areArraysOfObjectsEquivalent(value, initialValues[key]);
                } else {
                    hasChanged = initialValues[key] !== value;
                }
        
                if (hasChanged) {
                    accumulator[key] = value;
                }
        
                return accumulator;
          }, {});
    } else {
        return null;
    }
};
